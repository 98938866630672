import { Pipe, PipeTransform } from '@angular/core';
import { Physician, PhysicianDetails } from '@scp/common/core/models/physician';

/** Full name physician pipe. */
@Pipe({ name: 'physicianFullName' })
export class PhysicianFullNamePipe implements PipeTransform {

  /**
   * Returns the physician's full name with a prefix and academic degree.
   * @param physician Object of physician.
   */
  public transform(physician: PhysicianDetails | Physician): string {
    const prefix = physician.namePrefix ? `${physician.namePrefix} ` : '';
    return `${prefix}${physician.firstName} ${physician.lastName}`;
  }

}
