<div class="scpc-datepicker">
  <input
    type="text"
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    [(ngModel)]="controlValue"
    data-type="datepicker"
  />
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
