<aside class="sidebar">
  <nav class="sidebar__nav" [class.sidebar_collapsed]="!isOpened">
    <header class="sidebar__header">
      <section class="sidebar-item sidebar-toggle">
        <button
          type="button"
          (click)="onSidebarToggle($event)"
          class="sidebar-toggle__button"
        >
          <mat-icon
            [svgIcon]="isOpened ? 'angle-left' : 'angle-right'"
            class="sidebar-item__icon"
          ></mat-icon>
        </button>
        <span class="sidebar-item__text sidebar-toggle__text">
          Welcome,
          <ng-container *ngIf="user$ | async as user">
            {{ user.firstName }} {{ user.lastName }}
          </ng-container>
        </span>
      </section>
    </header>

    <section #sidebarBody class="sidebar__body">
      <nav>
        <a
          *ngFor="let navItem of navigationItems; trackBy: trackNavItem"
          class="sidebar-item nav-link"
          [routerLink]="navItem.routerLink"
          routerLinkActive="nav-link_active"
        >
          <mat-icon
            class="sidebar-item__icon nav-link__icon"
            [svgIcon]="navItem.icon"
          ></mat-icon>
          <span class="sidebar-item__text">{{ navItem.title }}</span>
        </a>
      </nav>

      <hr class="divider" />

      <ng-container *ngIf="isProductMenuShort === true; else productMenuButton">
        <scpw-products-menu
          [isProductMenuShort]="isProductMenuShort"
          [isProductsMenuOpened]="isProductsMenuOpened"
          [products]="products$ | async"
        ></scpw-products-menu>
      </ng-container>
    </section>

    <footer class="sidebar__footer">
      <hr class="divider" />
      <div class="sidebar-logo">
        <img
          class="sidebar-logo__sign"
          src="/assets/images/scriptera-sign.svg"
        />
        <img
          class="sidebar-logo__logotype"
          src="/assets/images/scriptera-logotype.svg"
        />
      </div>
    </footer>
  </nav>

  <ng-container *ngIf="isProductMenuShort === false">
    <scpw-products-menu
      [isProductMenuShort]="isProductMenuShort"
      [isProductsMenuOpened]="isProductsMenuOpened"
      [products]="products$ | async"
    ></scpw-products-menu>
  </ng-container>
</aside>

<ng-template #productMenuButton>
  <button
    type="button"
    class="sidebar-item nav-link products-menu-button"
    (click)="onProductsMenuToggle()"
    [class.products-menu-button_active]="isProductsMenuOpened"
  >
    <mat-icon class="sidebar-item__icon" svgIcon="prescription"></mat-icon>
    <span class="sidebar-item__text">
      My Products ({{ totalProductCount }})
    </span>
  </button>
</ng-template>
