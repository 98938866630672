import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDatasetInfo } from '@scp/common/core/models/chart-dataset-info';
import { ChartDatasetParams } from '@scp/common/core/models/chart-dataset-params';
import { CHART_LABEL_DATE_FORMAT, GENERAL_DATE_FORMAT } from '@scp/common/core/utils/date-formats';
import { BaseChartDirective, ChartLegendAlign, MAX_LEGEND_HEIGHT } from '@scp/common/shared/directives/base-chart.directive';
import { ChartComponent } from '@scp/common/shared/directives/hovered-chart-table-directives/hovered-chart.directive';
import { ChartData, ChartOptions } from 'chart.js';

/** Reject chart component.*/
@Component({
  selector: 'scpc-reject-chart',
  templateUrl: './reject-chart.component.html',
  styleUrls: ['./reject-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectChartComponent implements OnInit, OnChanges, ChartComponent {
  /** Chart info. */
  @Input()
  public chartInfo!: ChartDatasetInfo[];

  /** Dataset params for chart render. */
  @Input()
  public chartDatasetParams: ChartDatasetParams<'bar'>[] = [];

  /** Chart legend align. */
  @Input()
  public legendAlign: ChartLegendAlign = undefined;

  /** @inheritdoc */
  @ViewChild(BaseChartDirective)
  public chartRef: BaseChartDirective | null = null;

  /** Chart data. */
  protected data!: ChartData;

  /** @inheritdoc */
  public options!: ChartOptions;

  public constructor(@Inject(LOCALE_ID) private locale: string) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = this.getChartOptions();
    this.data = this.getChartData();
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.chartRef?.chart != null && 'chartInfo' in changes) {
      this.chartRef.chart.data = this.getChartData();
      this.chartRef.chart.update();
    }
  }

  private getLabels(): string[][] {
    return this.chartInfo[0].labels.map(dateLabel => formatDate(dateLabel, CHART_LABEL_DATE_FORMAT, this.locale).split(' '));
  }

  private getTooltipTitle(index: number): string {
    const dateLabel = this.chartInfo[0].labels[index];
    return formatDate(dateLabel, GENERAL_DATE_FORMAT, this.locale);
  }

  /** @inheritdoc */
  public getChartData(): ChartData {
    return {
      datasets: [
        {
          ...this.chartDatasetParams[0],
          data: this.chartInfo[0].values,
          label: 'Abandoned',

        },
        {
          ...this.chartDatasetParams[1],
          data: this.chartInfo[1].values,
          label: 'Rejected',

        },
      ],
      labels: this.getLabels(),
    };
  }

  private getChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
      },
      scales: {
        x: {
          grid: {
            borderDash: [4, 4],
          },
        },
        y: {
          grid: {
            borderDash: [4, 4],
          },
        },
      },
      plugins: {
        legend: {
          onClick: event => event.native?.preventDefault(),
          align: this.legendAlign,
          display: true,
          labels: {
            boxHeight: MAX_LEGEND_HEIGHT,
          },
        },
        tooltip: {
          backgroundColor: '#41337d',
          displayColors: false,
          callbacks: {
            title: items => this.getTooltipTitle(items[0].dataIndex),
          },
        },
      },
    };
  }
}
