import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizedGuard } from '@scp/common/core/guards/authorized.guard';
import { UnauthorizedGuard } from '@scp/common/core/guards/unauthorized.guard';

import { AsideLayoutComponent } from './layouts/aside-layout/aside-layout.component';
const routes: Routes = [
  {
    path: '',
    component: AsideLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./features/report-management/report-management.module').then(m => m.ReportManagementModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./features/users-management/users-management.module').then(m => m.UsersManagementModule),
      },
      {
        path: 'physicians',
        loadChildren: () =>
          import('./features/physicians/physicians.module').then(m => m.PhysiciansManagementModule),
      },
      {
        path: 'national-dispense',
        loadChildren: () =>
          import('./features/national-dispense/national-dispense.module').then(m => m.NationalDispenseModule),
      },
      {
        path: 'national-dispense/:state',
        loadChildren: () =>
          import('./features/state-statistics/state-statistics.module').then(m => m.StateStatisticsModule),
      },
      {
        path: 'daily-dispense-trend',
        loadChildren: () =>
          import('./features/daily-dispense-trend/daily-dispense-trend.module').then(m => m.DailyDispenseTrendModule),
      },
      {
        path: 'reject',
        loadChildren: () =>
          import('./features/reject/reject.module').then(m => m.RejectModule),
      },
      {
        path: 'fills-refills',
        loadChildren: () =>
          import('./features/refills/refills.module').then(m => m.RefillsModule),
      },
      {
        path: 'outstanding-refills',
        loadChildren: () =>
          import('./features/outstanding-refills/outstanding-refills.module').then(m => m.OutstandingRefillsModule),
      },
      {
        path: 'prior-auth',
        loadChildren: () =>
          import('./features/prior-auth/prior-auth.module').then(m => m.PriorAuthModule),
      },
    ],
    canActivate: [UnauthorizedGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthorizedGuard],
  },
  {
    path: 'report-unsubscribe/:reportToken',
    loadChildren: () =>
      import('./features/report-unsubscribe/report-unsubscribe.module').then(m => m.ReportUnsubscribeModule),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./features/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent),
  },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
