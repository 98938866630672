import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@scp/common/core/models/address';

/**
 * Make Address string.
 */
@Pipe({
  name: 'scpcAddress',
})
export class AddressPipe implements PipeTransform {
  /**
   * Transform value.
   * @param address Address.
   */
  public transform({ line1, line2, city, state, postalCode }: Address): string {
    if (line1 === '') {
      return '';
    }
    const address2 = line2 ? ` ${line2}` : '';
    return `${line1}${address2}, ${city}, ${state} ${postalCode}`;
  }
}
