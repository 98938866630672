import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input, OnInit } from '@angular/core';
import { FormControlDirective, NgControl, NgModel, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { listenControlTouched } from '@scp/common/core/utils/rxjs/listen-control-touched';
import { AppValidators } from '@scp/common/core/utils/validators';
import { EMPTY, merge, Observable, ReplaySubject, tap } from 'rxjs';
import { distinct, switchMap, map } from 'rxjs/operators';

const COMP_ID = 'scpc-label';

/**
 * Label component. Displays error and label for the input component.
 */
@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector -- Throw an error if we use constant withing selector string.
  selector: `${COMP_ID},[${COMP_ID}]`,
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent implements OnInit {

  /** Error text. */
  @Input()
  public set errorText(text: string | null) {
    if (text != null) {
      this.errors$.next(AppValidators.buildAppError(text));
    }
  }

  /** Text of control's label. */
  @Input()
  public labelText: string | null = null;

  /** Whether label is bound with required input. */
  @Input()
  public required = true;

  /** Is label visually hidden. */
  @Input()
  public visuallyHidden = false;

  /** Catch inner input by form control directive. */
  @ContentChild(NgControl)
  public set input(i: NgModel | FormControlDirective) {
    if (i) {
      this.input$.next(i);
    }
  }

  /** Errors stream. */
  protected readonly errors$ = new ReplaySubject<ValidationErrors | null>(1);

  private readonly input$ = new ReplaySubject<NgModel | FormControlDirective>(1);

  /**
   * Define if component is used as a separate element or it's attached to existing node.
   * @description Kind of workaround for the case when we don't want to have <label> to be used on template.
   * For example, while working with `<mat-radio-group>` because for radio buttons label should exist for every
   * radio button and not the whole group, although we want to have the same styles for the component.
   * @example
   * <scpc-label>...</scpc-label> <!-- isSeparateElement = true ->
   * <div scpc-label>....</div> <!-- isSeparateElement = false ->
   */
  protected readonly isSeparateElement: boolean;

  public constructor(elRef: ElementRef) {
    this.isSeparateElement = (elRef.nativeElement as HTMLElement).localName === COMP_ID;
  }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.initErrorStreamSideEffect().pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }

  private initErrorStreamSideEffect(): Observable<ValidationErrors | null> {
    return this.input$.pipe(
      distinct(),
      switchMap(input =>
        merge(
          input.statusChanges ?? EMPTY,
          listenControlTouched(input.control),
        ).pipe(
          map(() => input.touched ? input.errors : null),
        )),
      tap(errors => this.errors$.next(errors)),
    );
  }
}
