import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ReportFrequencyPeriod } from '../models/report-frequency';

import { ValidationErrorCode } from '../models/validation-error-code';

export namespace AppValidators {

  /**
   * Checks whether the current control matches another.
   * @param controlName Control name to check matching with.
   * @param controlTitle Control title to display for a user.
   */
  export function matchControl(
    controlName: string,
    controlTitle = controlName,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.parent &&
        control.parent.get(controlName)?.value !== control.value
      ) {
        return {
          [ValidationErrorCode.Match]: {
            controlName,
            controlTitle,
          },
        };
      }
      return null;
    };
  }

  /**
   * Create validation error from a message.
   * @param message Message to create an error from.
   */
  export function buildAppError(message: string): ValidationErrors {
    return {
      [ValidationErrorCode.AppError]: {
        message,
      },
    };
  }

  /**
   * Checks if report frequency is selected correctly.
   * @param control Form control.
   */
  export function reportFrequencyValidator(control: AbstractControl): ValidationErrors | null {
    switch (control.value.period) {
      case ReportFrequencyPeriod.Daily:
        return null;
      case ReportFrequencyPeriod.Weekly:
        return control.value.dayOfWeek === null ? {
          [ValidationErrorCode.Required]: ReportFrequencyPeriod.Weekly,
        } : null;
      case ReportFrequencyPeriod.Monthly:
        return control.value.dayOfMonth === null ? {
          [ValidationErrorCode.Required]: ReportFrequencyPeriod.Monthly,
        } : null;
      default:
        return {
          [ValidationErrorCode.Required]: ReportFrequencyPeriod.Daily,
        };
    }
  }
}
