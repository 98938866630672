<label class="label" *ngIf="isSeparateElement; else componentContent">
  <ng-container *ngTemplateOutlet="componentContent"></ng-container>
</label>

<ng-template #componentContent>
  <span
    class="label__content"
    [class.visually-hidden]="visuallyHidden"
    data-testid="label"
    *ngIf="labelText !== null"
  >
    {{ labelText }}
    <span *ngIf="required" class="required-mark">*</span>
  </span>
  <div class="label__input">
    <ng-content></ng-content>
  </div>
  <scpc-validation-message
    data-testid="error"
    class="error"
    [errors]="errors$ | async"
  >
  </scpc-validation-message>
</ng-template>
