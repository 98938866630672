import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { DateRange } from '../models/date-range';
import { Product } from '../models/product';
import { ProductFilterParams } from '../models/product-filter-params';

/** Product filter service. */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ProductFilterParamsService {

  private readonly _generalProductFilter$ = new BehaviorSubject<ProductFilterParams.General>({
    productId: '',
    dateRange: ProductFilterParams.dateRangeOptions[0].value,
  });

  private readonly _currentProduct$ = new ReplaySubject<Product>(1);

  /** General product filter. */
  public readonly generalProductFilter$ = this._generalProductFilter$.asObservable();

  private _currentProduct: Product | null = null;

  /**
   * Sets current product.
   * @param product Product.
   */
  public setCurrentProduct(product: Product): void {
    this.setFilterField('productId', product.id);
    this._currentProduct = product;
    this._currentProduct$.next(product);
  }

  /** Gets current product. */
  public getCurrentProduct(): Observable<Product> {
    return this._currentProduct$.asObservable();
  }

  /** Gets current product synchronously. */
  public getCurrentProductSync(): Product | null {
    return this._currentProduct;
  }

  /**
   * Sets filter field.
   * @param filterField Filter field.
   * @param value Field value.
   */
  public setFilterField(filterField: keyof ProductFilterParams.General, value: string | DateRange): void {
    this._generalProductFilter$.next({
      ...this._generalProductFilter$.getValue(),
      [filterField]: value,
    });
  }
}
