import { Injectable } from '@angular/core';

import { Product } from '../../models/product';

import { ProductAdditionDataDto } from './dto/favorite-product.dto';
import { ProductDto } from './dto/product.dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

const mockProductLogoUrl = '/assets/images/mock-product-logo.png';

/** Product mapper. */
@Injectable({ providedIn: 'root' })
export class ProductMapper implements IMapperFromDto<ProductDto, Product>, IMapperToDto<ProductAdditionDataDto, string> {

  /** @inheritdoc */
  public fromDto(productDto: ProductDto): Product {
    return {
      id: productDto.external_id,
      imageUrl: productDto.image ?? mockProductLogoUrl,
      iconUrl: productDto.icon ?? mockProductLogoUrl,
      name: productDto.name,
    };
  }

  /** @inheritdoc */
  public toDto(productId: string): ProductAdditionDataDto {
    return {
      product: productId,
    };
  }
}
