import {
  AfterContentInit,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PointChartData } from '@scp/common/core/models/point-chart';
import { assertNonNull } from '@scp/common/core/utils/assert-non-null';
import { ActiveElement, ChartData, ChartEvent, ChartOptions } from 'chart.js';

import { BaseChartDirective } from '../base-chart.directive';

/** Chart component. */
export interface ChartComponent {

  /** Reference to chart. */
  chartRef: BaseChartDirective | null;

  /** Chart options. */
  options: ChartOptions;

  /** Gets charts data. */
  getChartData(): ChartData;
}

/** Handles 'hover' of chart elements. */
@Directive({
  selector: '[scpcHoveredChart]',
})
export class HoveredChartDirective implements OnChanges, AfterContentInit, OnDestroy {

  /** Index of hovered item.  */
  @Input()
  public hoveredItem: PointChartData | null = null;

  /** Emits on chart's point hover. */
  @Output()
  public readonly pointHovered = new EventEmitter<PointChartData | null>();

  /** Chart component. */
  @Input()
  public chartComponent!: ChartComponent;

  private chartUnlisteners: Function[] = [];

  /** Handles 'mouseout'. */
  @HostListener('mouseout')
  public onMouseOut(): void {
    this.pointHovered.emit(null);
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    this.changeHoveredItem(changes);
  }

  /** @inheritdoc */
  public ngAfterContentInit(): void {
    this.setChartOptions();
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.unlistenChart();
  }

  private emitHoverEvent(_: ChartEvent, elements: ActiveElement[]): void {
    if (elements.length > 0 && this.isElementHovered(elements[0])) {
      const { datasetIndex, index } = elements[0];
      this.pointHovered.emit({ datasetIndex, index });
    } else if (elements.length === 0) {
      this.pointHovered.emit(null);
    }
  }

  private isElementHovered(element: ActiveElement): boolean {
    return this.hoveredItem?.index !== element.index &&
      this.hoveredItem?.datasetIndex !== element.datasetIndex;
  }

  private showHoveredItem(): void {
    const { chartRef } = this.chartComponent;
    assertNonNull(chartRef);
    if (chartRef.chart != null) {
      const { chart } = chartRef;

      const hoveredItems = this.hoveredItem !== null ?
        [this.hoveredItem] : [];

      chart?.setActiveElements(hoveredItems);

      const elementPosition = this.hoveredItem !== null && chart?.tooltip != null ?
        chart?.getActiveElements()[0].element.tooltipPosition() :
        { x: 0, y: 0 };
      chart?.tooltip?.setActiveElements(hoveredItems, elementPosition);
      chart.update();
    }
  }

  private setChartOptions(): void {
    this.chartComponent.options = {
      ...this.chartComponent.options,
      onHover: (event, elements) => this.emitHoverEvent(event, elements),
    };
  }

  private unlistenChart(): void {
    this.chartUnlisteners.forEach(unlisten => unlisten());
    this.chartUnlisteners = [];
  }

  private changeHoveredItem(changes: SimpleChanges): void {
    const { chartRef } = this.chartComponent;
    if (chartRef?.chart != null) {
      if ('hoveredItem' in changes) {
        this.showHoveredItem();
        chartRef.chart.update();
      }
    }
  }
}
