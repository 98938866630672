<div [class.chart_small]="isOnWidget" class="chart-container">
  <canvas
    class="chart"
    scpcBaseChart
    [data]="data"
    [options]="options"
    type="choropleth"
  >
  </canvas>
</div>
