import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlatControlsOf } from '@scp/common/core/utils/types/controls-of';
import { controlProviderFor, SimpleValueAccessor } from '@scp/common/core/utils/value-accessor';
import { filter } from 'rxjs';

interface DateRange {

  /** Date start. */
  readonly start: Date | null;

  /** Date end. */
  readonly end: Date | null;
}

type DateRangeFormGroup = FormGroup<FlatControlsOf<DateRange>>;

/** Multiple datepicker component. */
@UntilDestroy()
@Component({
  selector: 'scpc-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => RangeDatepickerComponent)],
})
export class RangeDatepickerComponent extends SimpleValueAccessor<DateRange> implements OnInit {

  /** Input placeholder. */
  @Input()
  public placeholder: HTMLInputElement['placeholder'] = 'MM DD YYYY';

  /** FormGroup for range datepicker. */
  protected readonly formGroup: DateRangeFormGroup;

  public constructor(
    private readonly fb: FormBuilder,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);

    this.formGroup = this.initFormGroup();
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      filter((dateRange): dateRange is Required<DateRange> => dateRange.start !== undefined && dateRange.end !== undefined),
      untilDestroyed(this),
    )
      .subscribe({
        next: dateRange => {
          this.emitChange(dateRange);
        },
      });
  }

  private initFormGroup(): DateRangeFormGroup {
    return this.fb.group({
      start: this.fb.control<Date | null>(null),
      end: this.fb.control<Date | null>(null),
    });
  }
}
