import { Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Chart, ChartConfiguration, ChartEvent, ChartType, DefaultDataPoint, Plugin } from 'chart.js';

export const MAX_LEGEND_HEIGHT = 5;
export type ChartLegendAlign = 'start' | 'end' | 'center' | undefined;

/** Base chart wrapper directive for chart.js. */
@Directive({
  selector: 'canvas[scpcBaseChart]',
})
export class BaseChartDirective <TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>, TLabel = unknown> implements OnDestroy, OnChanges {

  /** Chart type. */
  @Input()
  public type: ChartConfiguration<TType, TData, TLabel>['type'] = 'bar' as TType;

  /** Chart data. */
  @Input()
  public data?: ChartConfiguration<TType, TData, TLabel>['data'];

  /** Chart options. */
  @Input()
  public options: ChartConfiguration<TType, TData, TLabel>['options'];

  /** Chart plugins. */
  @Input()
  public plugins: Plugin<TType>[] = [];

  /** Chart labels. */
  @Input()
  public labels?: ChartConfiguration<TType, TData, TLabel>['data']['labels'];

  /** Chart datasets. */
  @Input()
  public datasets?: ChartConfiguration<TType, TData, TLabel>['data']['datasets'];

  /** Emits when chart is clicked. */
  @Output()
  public chartClick: EventEmitter<{ event?: ChartEvent; active?: {}[]; }> = new EventEmitter();

  /** Emits when chart is hovered. */
  @Output()
  public chartHover: EventEmitter<{ event: ChartEvent; active: {}[]; }> = new EventEmitter();

  /** Context. */
  public ctx: string;

  /** Chart instance. */
  public chart?: Chart<TType, TData, TLabel>;

  public constructor(element: ElementRef, private zone: NgZone) {
    this.ctx = element.nativeElement.getContext('2d');
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    const requireRender = ['type'];
    const propertyNames = Object.getOwnPropertyNames(changes);

    const isFirstChange = propertyNames.every(propertyName => changes[propertyName].isFirstChange());
    const isRenderRequired = propertyNames.some(propertyName => requireRender.includes(propertyName));

    if (isRenderRequired || isFirstChange) {
      this.render();
    } else {
      const config = this.getChartConfiguration();

      // Using assign to avoid changing the original object reference
      if (this.chart) {
        Object.assign(this.chart.config.data, config.data);
        if (this.chart.config.plugins) {
          Object.assign(this.chart.config.plugins, config.plugins);
        }
        if (this.chart.config.options) {
          Object.assign(this.chart.config.options, config.options);
        }
      }

      this.update();
    }
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  /** Renders the chart. */
  public render(): Chart<TType, TData, TLabel> {
    if (this.chart) {
      this.chart.destroy();
    }

    return this.zone.runOutsideAngular(() => {
      this.chart = new Chart(this.ctx, this.getChartConfiguration());
      return this.chart;
    });
  }

  /** Updates the chart. */
  public update(): void {
    if (this.chart) {
      this.zone.runOutsideAngular(() => this.chart?.update());
    }
  }

  /**
   * Hides dataset.
   * @param index Dataset index.
   * @param hidden Should hide dataset or not.
   */
  public hideDataset(index: number, hidden: boolean): void {
    if (this.chart) {
      this.chart.getDatasetMeta(index).hidden = hidden;
      this.update();
    }
  }

  /**
   * Checks if dataset is hidden or not.
   * @param index Dataset index.
   */
  public isDatasetHidden(index: number): boolean | undefined {
    return this.chart?.getDatasetMeta(index)?.hidden;
  }

  /** Converts chart to base 64 image. */
  public toBase64Image(): string | undefined {
    return this.chart?.toBase64Image();
  }

  /** Gets chart options. */
  private getChartOptions(): ChartConfiguration<TType, TData, TLabel>['options'] {
    const handlers = {
      onHover: (event: ChartEvent, active: {}[]) => {
        if (!this.chartHover.observed) {
          return;
        }

        this.zone.run(() => this.chartHover.emit({ event, active }));
      },
      onClick: (event?: ChartEvent, active?: {}[]) => {
        if (!this.chartClick.observed) {
          return;
        }

        this.zone.run(() => this.chartClick.emit({ event, active }));
      },
    };

    return {
      ...handlers,
      ...this.options,
    } as ChartConfiguration<TType, TData, TLabel>['options'];
  }

  /** Gets chart configuration. */
  private getChartConfiguration(): ChartConfiguration<TType, TData, TLabel> {
    return {
      type: this.type,
      data: this.getChartData(),
      options: this.getChartOptions(),
      plugins: this.plugins,
    };
  }

  /** Gets chart data. */
  private getChartData(): ChartConfiguration<TType, TData, TLabel>['data'] {
    return this.data ? this.data : {
      labels: this.labels ?? [],
      datasets: this.datasets ?? [],
    };
  }

}
