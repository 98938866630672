import { Pipe, PipeTransform } from '@angular/core';

const mockAvatarImage = 'assets/images/avatar-placeholder.png';

/** User avatar with placeholder. */
@Pipe({ name: 'userAvatarWithPlaceholder' })
export class UserAvatarWithPlaceholderPipe implements PipeTransform {

  /**
   * If the user does not have an avatar, then a placeholder is placed.
   * @param userAvatarUrl User avatar URL.
   * @param placeholder Mock user avatar URL.
   */
  public transform(userAvatarUrl: string | null, placeholder = mockAvatarImage): string {
    return userAvatarUrl ?? placeholder;
  }

}
