import { style, animate, trigger, transition, state } from '@angular/animations';

export const expandCollapse = trigger('expandCollapse', [
  state(
    'expanded',
    style({
      overflow: 'hidden',
    }),
  ),
  state(
    'collapsed',
    style({
      height: 0,
      opacity: 0,
      overflow: 'hidden',
    }),
  ),
  transition('collapsed <=> expanded', animate('300ms ease-in-out')),
]);
