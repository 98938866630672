export const ONE_DAY_MILLISECONDS = 1000 * 3600 * 24;

/**
 * Subtracts from date number of days.
 * @param date Some date.
 * @param days Number of days.
 */
export function subDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}
