import { Injectable } from '@angular/core';

import { DailyDispense } from '../../models/daily-dispense';
import { Percents } from '../../models/percents';

import { DailyDispenseDto } from './dto/daily-dispense.dto';
import { IMapperFromDto } from './mappers';

/** Daily dispense mapper. */
@Injectable({
  providedIn: 'root',
})
export class DailyDispenseMapper implements IMapperFromDto<DailyDispenseDto, DailyDispense> {

  /** @inheritdoc */
  public fromDto(dto: DailyDispenseDto): DailyDispense {
    return {
      id: dto.id,
      productId: dto.product_id,
      count: dto.count,
      date: new Date(dto.date),
      change: new Percents(Number(dto.percentage_change)),
    };
  }
}
