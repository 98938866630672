import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, merge, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { FavoriteProduct } from '../models/favorite-product';
import { takeOneOrUntilDestroy } from '../utils/rxjs/take-one-or-until-destroy';

import { FavoriteProductApiService } from './favorite-product-api.service';

const DEBOUNCE_TIME_MS = 500;

/** Favorite product service. */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class FavoriteProductService {

  /** Observable with current favorite products list. */
  public readonly currentList$: Observable<FavoriteProduct[]>;

  private readonly listChanges$ = new BehaviorSubject({});

  private readonly _currentList$ = new BehaviorSubject<FavoriteProduct[]>([]);

  /** Current favorite product list.  */
  private readonly fetchedList$: Observable<FavoriteProduct[]>;

  public constructor(
    private readonly favoriteProductApiService: FavoriteProductApiService,
  ) {
    this.fetchedList$ = this.listChanges$.pipe(
      debounceTime(DEBOUNCE_TIME_MS),
      switchMap(() => this.favoriteProductApiService.getList()),
    );

    this.currentList$ = merge(
      this._currentList$.asObservable(),
      this.fetchedList$,
    ).pipe(
      distinctUntilChanged((previousList, currentList) => JSON.stringify(previousList) === JSON.stringify(currentList)),
      tap(list => this._currentList$.next(list)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Add product to favorites.
   * @param productId Product id.
   */
  public addProductToFavorites(productId: string): void {
    const createFavoriteProduct$ = this.has(productId).pipe(

      // We do not add a product to favorites if it is already there.
      filter(isExist => isExist !== true),
      switchMap(() => this.favoriteProductApiService.create(productId)),
    );

    createFavoriteProduct$.pipe(
      tap(addedProduct => {
        this._currentList$.next(this._currentList$.value.concat(addedProduct));
        this.listChanges$.next({});
      }),
      takeOneOrUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Removes product from favorites.
   * @param favoriteProductId Favorite product id.
   */
  public removeProductFromFavorites(favoriteProductId: number): void {
    this.favoriteProductApiService.delete(favoriteProductId)
      .pipe(
        tap(() => {
          this._currentList$.next(this._currentList$.value.filter(product => product.id !== favoriteProductId));
          this.listChanges$.next({});
        }),
        takeOneOrUntilDestroy(this),
      )
      .subscribe();
  }

  /**
   * Is the product in the list of favorite products.
   * @param productId Product id.
   */
  private has(productId: string): Observable<boolean> {
    return this._currentList$.pipe(
      takeOneOrUntilDestroy(this),
      map(favoriteProducts => !favoriteProducts.every(favoriteProduct => favoriteProduct.product.id !== productId)),
    );
  }
}
