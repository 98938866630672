import { Injectable } from '@angular/core';

import { Topology } from 'topojson-specification';
import { topojson, Feature } from 'chartjs-chart-geo';

/** Service for geo chart. */
@Injectable({
  providedIn: 'root',
})
export class GeoChartService {

  /**
   * Initialize nations for geo chart.
   * @param us US atlas.
   */
  public initNation(us: Topology): Feature {
    /** Type casting is used to prevent ChartGeo types issues. */
    return (topojson.feature(
      us,
      us.objects['nation'],
    ) as GeoJSON.FeatureCollection).features[0];
  }

  /**
   * Initialize states for geo chart.
   * @param us US atlas.
   */
  public initStates(us: Topology): Feature[] {
    /** Type casting is used to prevent ChartGeo types issues. */
    return (topojson.feature(
      us,
      us.objects['states'],
    ) as GeoJSON.FeatureCollection).features;
  }
}
