import {
  AfterViewChecked,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { PointChartData } from '@scp/common/core/models/point-chart';

/** Table component. */
export interface TableComponent {

  /** Reference to table. */
  // Table can be with any data in generic.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly tableRef: MatTable<any> | null;
}

/** Handles 'hover' of table elements. */
@Directive({
  selector: '[scpcHoveredTable]',
})
export class HoveredTableDirective implements OnChanges, AfterViewChecked, OnDestroy {

  /** Table data. */
  @Input()
  public tableData: unknown[] = [];

  /** Index of hovered item. */
  @Input()
  public hoveredItem: PointChartData | null = null;

  /** Emits on table's cell hover. */
  @Output()
  public readonly cellHovered = new EventEmitter<PointChartData | null>();

  /** Table component. */
  @Input()
  public tableComponent!: TableComponent;

  private isTableHovered = false;

  private isRowChange = false;

  private rowUnlisteners: Function[] = [];

  public constructor(
    private readonly renderer2: Renderer2,
  ) { }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    this.changeTableData(changes);
    this.changeHoveredItem(changes);
  }

  /** @inheritdoc */
  public ngAfterViewChecked(): void {
    this.setRowsHoveredEvents();
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.unlistenRows();
  }

  private emitHoverEvent(_: Event, point: PointChartData): void {
    this.isTableHovered = true;
    this.cellHovered.emit(point);
  }

  private onMouseOut(): void {
    this.isTableHovered = false;
    this.cellHovered.emit(null);
  }

  private scrollToHoveredRow(): void {
    if (this.tableComponent.tableRef == null || this.hoveredItem == null) {
      return;
    }

    const hoveredRow = this.tableComponent.tableRef._getRenderedRows(this.tableComponent.tableRef._rowOutlet)[this.hoveredItem.index];
    if (hoveredRow != null && !this.isTableHovered) {
      hoveredRow.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  private setRowsHoveredEvents(): void {
    if (this.isRowChange) {
      return;
    }
    this.isRowChange = true;

    this.unlistenRows();

    const rows = this.tableComponent.tableRef?._getRenderedRows(this.tableComponent.tableRef._rowOutlet);
    rows?.forEach((row, indexRow) => {

      const cells = row.querySelectorAll('td.chartData');
      if (cells.length > 1) {
        cells.forEach((cell, indexCell) => {
          this.rowUnlisteners.push(
            this.renderer2.listen(cell, 'mouseover', (_: Event) => this.emitHoverEvent(_, { datasetIndex: indexCell, index: indexRow })),
          );
          this.rowUnlisteners.push(this.renderer2.listen(cell, 'mouseout', (_: Event) => this.onMouseOut()));
        });
      } else {
        this.rowUnlisteners.push(
          this.renderer2.listen(row, 'mouseover', (_: Event) => this.emitHoverEvent(_, { datasetIndex: 0, index: indexRow })),
        );
        this.rowUnlisteners.push(this.renderer2.listen(row, 'mouseout', (_: Event) => this.onMouseOut()));
      }
    });
  }

  private changeTableData(changes: SimpleChanges): void {
    if ('tableData' in changes) {
      this.isRowChange = false;
    }
  }

  private changeHoveredItem(changes: SimpleChanges): void {
    if ('hoveredItem' in changes) {
      this.scrollToHoveredRow();
    }
  }

  private unlistenRows(): void {
    this.rowUnlisteners.forEach(unlisten => unlisten());
    this.rowUnlisteners = [];
  }
}
