import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { expandCollapse } from '@scp/common/core/animation';

/**
 * Component of expansion panel.
 */
@Component({
  selector: 'scpc-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.css'],
  animations: [expandCollapse],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent implements OnInit {

  /** Title of the panel. */
  @Input()
  public panelTitle = '';

  /** Is panel collapsible? */
  @Input()
  public isCollapsible = true;

  /** Is panel opened. */
  public isOpened = false;

  /** On click panel. */
  public onPanelClick(): void {
    if (this.isCollapsible) {
      this.isOpened = !this.isOpened;
    }
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.isOpened = !this.isCollapsible;
  }

}
