type PaginationConstructorData<T> = Pick<Pagination<T>, 'items' | 'totalCount' | 'haveNext' | 'havePrev'>;

/** Pagination wrapper. */
export class Pagination<T> {

  /** Items on the page. */
  public readonly items: T[];

  /** Total count in the store. */
  public readonly totalCount: number;

  /** Whether the pagination have next pages. */
  public readonly haveNext: boolean;

  /** Whether the pagination have prev pages. */
  public readonly havePrev: boolean;

  public constructor(
    data: PaginationConstructorData<T>,
  ) {
    this.items = data.items;
    this.totalCount = data.totalCount;
    this.haveNext = data.haveNext;
    this.havePrev = data.havePrev;
  }

  /** Whether items is present or not.  */
  public get hasItems(): boolean {
    return this.items.length > 0;
  }
}
