import { Injectable } from '@angular/core';

import { OutstandingRefills } from '../../models/outstanding-refills';
import { OutstandingRefillsTotalStats } from '../../models/outstanding-refills-total-stats';
import { Percents } from '../../models/percents';

import { OutstandingRefillsTotalStatsDto } from './dto/outstanding-refills-total-stats.dto';

import { OutstandingRefillsDto } from './dto/outstanding-refills.dto';

import { IMapperFromDto } from './mappers';

/** Outstanding refills mapper. */
@Injectable({
  providedIn: 'root',
})
export class OutstandingRefillsMapper implements IMapperFromDto<OutstandingRefillsDto, OutstandingRefills> {

  /** @inheritdoc */
  public fromDto(dto: OutstandingRefillsDto): OutstandingRefills {
    return {
      productId: dto.product_id,
      date: new Date(dto.date),
      outstandingRefillsCount: dto.outstanding_refills_count,
    };
  }

  /** @inheritdoc */
  public totalStatsFromDto(dto: OutstandingRefillsTotalStatsDto): OutstandingRefillsTotalStats {
    return {
      todayOutstandingRefillsChangePercentage: new Percents(Number(dto.today_outstanding_refills_change_percentage)),
      todayOutstandingRefillsCount: dto.today_outstanding_refills_count,
      totalOutstandingRefillsChangePercentage: new Percents(Number(dto.total_outstanding_refills_change_percentage)),
      totalOutstandingRefillsCount: dto.total_outstanding_refills_count,
    };
  }
}
