/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, OnChanges, Input, HostBinding, ElementRef } from '@angular/core';

/**
 * Generates random value between specified ranges using linear random generator.
 * @param max Max limit.
 * @param min Min.
 */
export function generateRandomInRange(max: number, min: number): number {
  return Math.random() * (max - min) + min;
}

/** The directive applies a skeleton class whenever the passed value is void. */
@Directive({ selector: '[scpcSkeleton]' })
export class SkeletonDirective implements OnChanges {

  /** The value to check whether the skeleton should be applied. */
  @Input('scpcSkeleton')
  public value?: unknown;

  /** The skeleton class binding. */
  @HostBinding('class.app-skeleton')
  public get skeletonClass(): boolean {
    return this.value == null || this.value === '';
  }

  /** Min width to generate. */
  @Input('scpcSkeletonMinWidth')
  public min: number | undefined;

  /** Max width to generate. */
  @Input('scpcSkeletonMaxWidth')
  public max: number | undefined;

  /** CSS width units. */
  @Input()
  public appSkeletonWidthUnits = 'ch';

  /** Width of skeleton in `appWidthUnits`. */
  @HostBinding('style.width')
  public get width(): string | undefined {
    if (this._width != null) {
      return `${this._width}${this.appSkeletonWidthUnits}`;
    }

    return undefined;
  }

  /** Display binding. If width attribute is passed, display inline-block is set so that the width would work. */
  @HostBinding('style.display')
  public get display(): string {
    if (this.width != null) {
      return `inline-block`;
    }

    return this.elementRef.nativeElement.style.display;
  }

  private _width: number | null = null;

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  /** @inheritdoc */
  public ngOnChanges(): void {
    if (this.max == null || this.min == null) {
      return;
    }

    const valueIsPresent = this.value != null && this.value !== '';
    this._width = valueIsPresent ? null : generateRandomInRange(this.min, this.max);
  }
}
