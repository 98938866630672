import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationService } from '@scp/common/core/services/navigation.service';

/** Root component. */
@Component({
  selector: 'scpw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  public constructor(private readonly navigationService: NavigationService) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.navigationService.navigationSideEffect$.subscribe();
  }
}
