import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';

/** Navigation service.  */
@Injectable({ providedIn: 'root' })
export class NavigationService {

  /**  Side effect for writing URL paths to the history of URLs visited by the user. */
  public navigationSideEffect$: Observable<never>;

  private readonly history: string[] = [];

  public constructor(
    private router: Router,
  ) {
    this.navigationSideEffect$ = this.router.events.pipe(
      tap(event => {
        if (event instanceof NavigationEnd) {
          const urlWithoutParams = event.url.split('?')[0];
          if (!this.history.includes(urlWithoutParams)) {
            this.history.push(urlWithoutParams);
          }
        }
      }),
    ) as Observable<never>;
  }

  /** Go to previous route. */
  public back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.router.navigateByUrl(this.history[history.length]);
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
