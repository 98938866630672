import { BaseFilterParams } from './base-filter-params';
import { ProductFilterParams } from './product-filter-params';
import { Sort } from './sort';

/** National dispense rate sort fields. */
export enum NationalDispenseSortField {
  FillsCount = 'fillsCount',
  AbandonmentsCount = 'abandonmentsCount',
}

/** National dispense sort.  */
export type NationalDispenseSort = Sort<NationalDispenseSortField>;

/** National dispense filter params. */
export interface NationalDispenseFiltersParams extends BaseFilterParams.Pagination, ProductFilterParams.GeneralDateRange {

  /** Sort. */
  readonly sort?: NationalDispenseSort;

  /** State abbreviation. */
  readonly state?: string;
}
