import { Injectable } from '@angular/core';

import { Indicator } from '../../models/indicator';

import { IndicatorDto } from './dto/indicator.dto';
import { IMapperFromDto } from './mappers';

/** Indicator mapper. */
@Injectable({
  providedIn: 'root',
})
export class IndicatorMapper implements IMapperFromDto<IndicatorDto, Indicator> {

  /** @inheritdoc */
  public fromDto(data: IndicatorDto): Indicator {
    return {
      description: data.description,
      count: data.count,
    };
  }

}
