import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Percents } from '@scp/common/core/models/percents';

/** Classes for percentage change block. */
enum PercentChangeClass {
  Positive = 'percent_positive',
  Negative = 'percent_negative',
  None = 'percent_none',
}

/**
 * Component display change in percentage.
 */
@Component({
  selector: 'scpc-percent-change',
  templateUrl: './percent-change.component.html',
  styleUrls: ['./percent-change.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentChangeComponent {

  /** Change in percentage, 1 means 100%. */
  @Input()
  public percent = new Percents();

  /** Get class for percent layout. */
  public getPercentClass(): PercentChangeClass {
    if (this.percent.valueProportion === 0) {
      return PercentChangeClass.None;
    }
    if (this.percent.valueProportion < 0) {
      return PercentChangeClass.Negative;
    }
    return PercentChangeClass.Positive;
  }
}
