import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDatasetInfo } from '@scp/common/core/models/chart-dataset-info';
import { ChartDatasetParams } from '@scp/common/core/models/chart-dataset-params';
import { CHART_LABEL_DATE_FORMAT, GENERAL_DATE_FORMAT } from '@scp/common/core/utils/date-formats';
import { BaseChartDirective } from '@scp/common/shared/directives/base-chart.directive';
import { ChartOptions, ChartData } from 'chart.js';

/** Outstanding refills chart. */
@Component({
  selector: 'scpc-outstanding-refills-chart',
  templateUrl: './outstanding-refills-chart.component.html',
  styleUrls: ['./outstanding-refills-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutstandingRefillsChartComponent implements OnInit, OnChanges {
  /** Chart info. */
  @Input()
  public chartInfo!: ChartDatasetInfo;

  /** Dataset params for chart render. */
  @Input()
  public chartDatasetParams: ChartDatasetParams<'bar'> | null = null;

  /** @inheritdoc */
  @ViewChild(BaseChartDirective)
  public chartRef: BaseChartDirective | null = null;

  /** @inheritdoc */
  public options: ChartOptions;

  /** Chart data. */
  protected data!: ChartData;

  public constructor(@Inject(LOCALE_ID) private locale: string) {
    this.options = this.getChartOptions();
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.data = this.getChartData();
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.chartRef?.chart != null) {
      if ('chartInfo' in changes) {
        this.chartRef.chart.data = this.getChartData();
        this.chartRef.chart.update();
      }
    }
  }

  /** @inheritdoc */
  public getChartData(): ChartData {
    return {
      datasets: [
        {
          ...this.chartDatasetParams,
          data: this.chartInfo.values,
          label: 'Outstanding refills',

        },
      ],
      labels: this.getLabels(),
    };
  }

  private getLabels(): string[][] {
    return this.chartInfo.labels.map(dateLabel => formatDate(dateLabel, CHART_LABEL_DATE_FORMAT, this.locale).split(' '));
  }

  private getTooltipTitle(index: number): string {
    const dateLabel = this.chartInfo.labels[index];
    return formatDate(dateLabel, GENERAL_DATE_FORMAT, this.locale);
  }

  private getChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            borderDash: [4, 4],
          },
        },
        y: {
          grid: {
            borderDash: [4, 4],
          },
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          backgroundColor: '#41337d',
          displayColors: false,
          callbacks: {
            title: items => this.getTooltipTitle(items[0].dataIndex),
          },
        },
      },
    };
  }
}
