import { Injectable } from '@angular/core';

import { UserSecret } from '../../models/user-secret';

import { UserSecretDto } from './dto/user-secret.dto';
import { IMapperFromDto } from './mappers';

/** User secret mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserSecretDataMapper implements IMapperFromDto<UserSecretDto, UserSecret> {

  /** @inheritdoc */
  public fromDto(dto: UserSecretDto): UserSecret {
    return {
      token: dto.token,
    };
  }
}
