import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';

import { FavoriteProduct } from '../models/favorite-product';

import { AppUrlsConfig } from './app-urls.config';
import { FavoriteProductDto } from './mappers/dto/favorite-product.dto';
import { FavoriteProductMapper } from './mappers/favorite-product.mapper';
import { ProductMapper } from './mappers/product.mapper';

/** Favorite product API service. */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class FavoriteProductApiService {

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly apiUrls: AppUrlsConfig,
    private readonly favoriteProductMapper: FavoriteProductMapper,
    private readonly productMapper: ProductMapper,
  ) {

  }

  /** Gets favorite products. */
  public getList(): Observable<FavoriteProduct[]> {
    return this.httpClient.get<FavoriteProductDto[]>(this.apiUrls.favoriteProducts.list).pipe(
      map(favoriteProductsDto => favoriteProductsDto.map(favoriteProductDto => this.favoriteProductMapper.fromDto(favoriteProductDto))),
    );
  }

  /**
   * Creates and return favorite product.
   * @param productId Product id.
   */
  public create(productId: string): Observable<FavoriteProduct> {
    const body = this.productMapper.toDto(productId);
    return this.httpClient.post<FavoriteProductDto>(this.apiUrls.favoriteProducts.list, body).pipe(
      map(favoriteProductDto => this.favoriteProductMapper.fromDto(favoriteProductDto)),
    );
  }

  /**
   * Delete favorite product.
   * @param favoriteProductId Favorite product ID.
   */
  public delete(favoriteProductId: number): Observable<void> {
    return this.httpClient.delete(this.apiUrls.favoriteProducts.entity(favoriteProductId)).pipe(
      map(() => undefined),
    );
  }

}
