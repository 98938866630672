import { HttpParams } from '@angular/common/http';

import { subDays } from '../utils/date';

import { ProductFilterParamsDto } from '../services/mappers/dto/product-filter-params.dto';
import { composeHttpParams } from '../utils/compose-http-params';

import { DateRange } from './date-range';

/** Product filter params. */
export namespace ProductFilterParams {

  /** General product filter params. */
  export interface General {

    /** Product ID. */
    readonly productId: string;

    /** Date range. */
    readonly dateRange: DateRange;
  }

  /** Date range option. */
  export interface DateRangeOption {

    /** Option value. */
    readonly value: DateRange;

    /** Option text. */
    readonly text: string;
  }

  export type GeneralDateRange = Omit<ProductFilterParams.General, 'productId'>;

  const TODAY_DATE = new Date();

  export const dateRangeOptions: readonly DateRangeOption[] = [
    // TODO (Gazzaev A.): Only for dev. Delete before merging in production.
    {
      value: new DateRange({
        start: new Date('2022-02-27'),
        end: new Date('2023-02-27'),
      }),
      text: 'All days',
    },
    {
      value: new DateRange({
        start: subDays(TODAY_DATE, 60),
        end: TODAY_DATE,
      }),
      text: 'Past 60 Days',
    },
    {
      value: new DateRange({
        start: subDays(TODAY_DATE, 30),
        end: TODAY_DATE,
      }),
      text: 'Past 30 Days',
    },
    {
      value: new DateRange({
        start: subDays(TODAY_DATE, 7),
        end: TODAY_DATE,
      }),
      text: 'Past 7 Days',
    },
  ];

  /**
   * Get data range request params.
   * @param dateRangeDto DateRangeDto.
   */
  export function getDataRangeHttpParams(dateRangeDto: ProductFilterParamsDto.ProductDateRange): HttpParams {
    return composeHttpParams(dateRangeDto);
  }

}
