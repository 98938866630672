import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Product } from '@scp/common/core/models/product';
import { FavoriteProductService } from '@scp/common/core/services/favorite-product.service';
import { ProductFilterParamsService } from '@scp/common/core/services/product-filter-params.service';
import { createTrackByFunction } from '@scp/common/core/utils/trackby';
import { Observable } from 'rxjs';

/** Products menu component. */
@UntilDestroy()
@Component({
  selector: 'scpw-products-menu',
  templateUrl: './products-menu.component.html',
  styleUrls: ['./products-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsMenuComponent {

  /** Should the menu be shortened. */
  @Input()
  public isProductMenuShort!: boolean;

  /** Is products menu opened. */
  @Input()
  public isProductsMenuOpened = false;

  /** Product list. */
  @Input()
  public products!: readonly Product[] | null;

  /** Current product. */
  protected readonly currentProduct$: Observable<Product>;

  /** Tracks by ID for product list. */
  protected readonly trackByProductId = createTrackByFunction<Product>('id');

  public constructor(
    private readonly productFilterService: ProductFilterParamsService,
    private readonly favoriteProductService: FavoriteProductService,
  ) {
    this.currentProduct$ = productFilterService.getCurrentProduct();
  }

  /**
   * Handles product select.
   * @param product Selected product.
   */
  public onProductSelect(product: Product): void {
    this.favoriteProductService.addProductToFavorites(product.id);
    this.productFilterService.setCurrentProduct(product);
  }

}
