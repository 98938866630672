import { Injectable } from '@angular/core';

import { Percents } from '../../models/percents';

import { PhysicianDetails } from '../../models/physician';

import { PhysicianDetailsDto } from './dto/physicians.dto';
import { IndicatorMapper } from './indicator.mapper';
import { IMapperFromDto } from './mappers';
import { PhysicianMapper } from './physician.mapper';

/** Physician details mapper. */
@Injectable({ providedIn: 'root' })
export class PhysicianDetailsMapper implements IMapperFromDto<PhysicianDetailsDto, PhysicianDetails> {

  public constructor(
    private readonly physicianMapper: PhysicianMapper,
    private readonly indicatorMapper: IndicatorMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(physicianDetailsDto: PhysicianDetailsDto): PhysicianDetails {
    return {
      ...this.physicianMapper.fromDto(physicianDetailsDto),
      metrics: {
        abandonedRejectedPrescriptions: new Percents(physicianDetailsDto.abandoned_rejected_percent),
        priorAuthorization: new Percents(physicianDetailsDto.prior_auth_percent),
        copayAssistance: new Percents(physicianDetailsDto.copay_assist_percent),
        averageRefillsRerPrescription: physicianDetailsDto.average_refills,
        brand: new Percents(physicianDetailsDto.brand_generic_percents.brand_count_percentage_diff),
        generic: new Percents(physicianDetailsDto.brand_generic_percents.generic_count_percentage_diff),
        topDispensed: physicianDetailsDto.top_dispensed.map(item => this.indicatorMapper.fromDto(item)),
        topPrescribed: physicianDetailsDto.top_prescribed.map(item => this.indicatorMapper.fromDto(item)),
        topPlans: physicianDetailsDto.top_plan.map(item => this.indicatorMapper.fromDto(item)),
      },
    };
  }

}
