import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfig } from '@scp/common/core/services/app.config';
import { IconsService } from '@scp/common/core/services/icons.service';
import { GENERAL_DATE_FORMAT } from '@scp/common/core/utils/date-formats';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './core/providers/http-interceptor-providers';
import { materialProviders } from './core/providers/material-providers';
import { WebAppConfig } from './features/shared/web-app.config';
import { AsideLayoutModule } from './layouts/aside-layout/aside-layout.module';
import { ICONS, USA_ICONS } from './shared/utils/icons-declaration';

const GENERAL_PAGE_SIZES = [5, 10, 25, 100];

/** Root module. */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AsideLayoutModule,
  ],
  providers: [
    ...httpInterceptorProviders,
    ...materialProviders,
    { provide: AppConfig, useClass: WebAppConfig },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: GENERAL_DATE_FORMAT } },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { pageSizeOptions: GENERAL_PAGE_SIZES } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(iconsService: IconsService) {
    ICONS.forEach(icon => iconsService.addIcon(icon, '/assets/icons/'));
    USA_ICONS.forEach(icon => iconsService.addIcon(icon, '/assets/icons/'));
  }
}
