import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@scp/common/core/interceptors/auth.interceptor';
import { ExpiredTokenInterceptor } from '@scp/common/core/interceptors/expired-token.interceptor';
import { ProductIdInterceptor } from '@scp/common/core/interceptors/product-id.interceptor';

/**
 * Providers for HTTP_INTERCEPTORS of HTTP module.
 */
export const httpInterceptorProviders = [
  // The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ExpiredTokenInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProductIdInterceptor, multi: true },
];
