import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { PasswordChange } from '../../models/password-change';

import { PasswordChangeDto } from './dto/password-change.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessageByField } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/**
 * Mapper for change password process.
 */
@Injectable({ providedIn: 'root' })
export class PasswordChangeMapper implements
  IMapperToDto<PasswordChangeDto, PasswordChange>,
  IValidationErrorMapper<PasswordChangeDto, PasswordChange> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<PasswordChangeDto>,
  ): EntityValidationErrors<PasswordChange> {
    return {
      password: extractErrorMessageByField('old_password', errorDto),
      newPassword: extractErrorMessageByField('new_password', errorDto),
      newPasswordConfirmation: extractErrorMessageByField(
        'new_password_confirm',
        errorDto,
      ),
    };
  }

  /** @inheritdoc */
  public toDto(data: PasswordChange): PasswordChangeDto {
    return {
      old_password: data.password,
      new_password: data.newPassword,
      new_password_confirm: data.newPasswordConfirmation,
    };
  }
}
