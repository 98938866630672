import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Layout component with sidebar and header. */
@Component({
  selector: 'scpw-aside-layout',
  templateUrl: './aside-layout.component.html',
  styleUrls: ['./aside-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLayoutComponent {

  /** Is sidebar expaneded. */
  protected isSidebarOpened = false;

  /** Is product list expaneded. */
  protected isProductListOpened = false;

  /**
   * Sets sidebar opened.
   * @param state Is sidebar open.
   */
  protected setSidebarOpened(state: boolean): void {
    this.isSidebarOpened = state;
  }

  /**
   * Sets product list opened.
   * @param state Is product list open.
   */
  protected setProductListOpened(state: boolean): void {
    this.isProductListOpened = state;
  }

  /** Closes entire sidebar. */
  protected onSidebarClose(): void {
    this.isSidebarOpened = false;
    this.isProductListOpened = false;
  }
}
