import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserRole } from '@scp/common/core/models/user-role';
import { UserService } from '@scp/common/core/services/user.service';
import { filterNull } from '@scp/common/core/utils/rxjs/filter-null';
import { combineLatest, map, ReplaySubject } from 'rxjs';

/**
 * Structural directive that gets a list of roles and show an element if current user roles is in the list.
 */
@UntilDestroy()
@Directive({
  selector: '[scpcAllowedRoles]',
})
export class AllowedRolesDirective implements OnInit {

  /** Roles list. */
  @Input()
  public set scpcAllowedRoles(roles: UserRole[]) {
    this.roles$.next(roles);
  }

  private readonly roles$ = new ReplaySubject<UserRole[]>(1);

  public constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly userService: UserService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const user$ = this.userService.currentUser$.pipe(
      filterNull(),
    );

    combineLatest([
      user$,
      this.roles$,
    ]).pipe(
      map(([user, roles]) => roles.includes(user.role)),
      untilDestroyed(this),
    )
      .subscribe(userRoleInList => userRoleInList ?
        this.renderContent() :
        this.clearContent());
  }

  /** Render content. */
  private renderContent(): void {
    this.viewContainer.clear();
    if (!this.templateRef) {
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
    this.changeDetector.markForCheck();
  }

  /** Remove content. */
  private clearContent(): void {
    this.viewContainer.clear();
    this.changeDetector.markForCheck();
  }
}
