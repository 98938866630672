import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { filter, map, Observable, switchMap } from 'rxjs';

import { AppUrlsConfig } from '../services/app-urls.config';
import { ProductFilterParamsService } from '../services/product-filter-params.service';
import { ProductFilterParamsMapper } from '../services/mappers/product-filter-params.mapper';

/** Product id interceptor. Adds id parameter queries to all `products` endpoints. */
@Injectable()
export class ProductIdInterceptor implements HttpInterceptor {

  public constructor(
    private readonly appUrlsConfig: AppUrlsConfig,
    private readonly productFilterService: ProductFilterParamsService,
    private readonly productFilterParamsMapper: ProductFilterParamsMapper,
  ) { }

  /** @inheritdoc */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url } = request;
    if (this.isProductUrl(url)) {
      return this.productFilterService.generalProductFilter$.pipe(
        filter(({ productId }) => productId !== ''),
        map(productFilter => {
          const productIdFilter = this.productFilterParamsMapper.productIdToDto(productFilter);
          const params = request.params.appendAll({ ...productIdFilter });
          return request.clone({ params });
        }),
        switchMap(newRequest => next.handle(newRequest)),
      );
    }

    // Do nothing.
    return next.handle(request);
  }

  /**
   * The URL must belong to the endpoint products class.
   * Except endpoint `products/products/`.
   * @param url Some URL.
   */
  public isProductUrl(url: string): boolean {
    return url.includes('/products/') && !url.includes(this.appUrlsConfig.products.list);
  }
}
