<section
  [ngClass]="{
    'sidebar__products-menu': !isProductMenuShort,
    'products-menu': !isProductMenuShort,
    'products-menu_short': isProductMenuShort,
    'products-menu_collapsed': !isProductsMenuOpened
  }"
  *ngIf="currentProduct$ | async as currentProduct"
>
  <ul class="products-menu__list">
    <li
      class="products-menu__item"
      [class.products-menu__item_current]="product.id === currentProduct.id"
      *ngFor="let product of products; trackBy: trackByProductId"
    >
      <button
        type="button"
        class="products-menu__button"
        [title]="product.name"
        [class.sidebar-item]="isProductMenuShort"
        (click)="onProductSelect(product)"
      >
        <img class="products-menu__icon" [src]="product.iconUrl" alt="" />
        <span class="products-menu__text"> {{ product.name }}</span>
      </button>
    </li>
  </ul>
</section>
