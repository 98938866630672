import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

/** Catches requests with outdated tokens and attempts to refresh it and then retry the request. */
@Injectable()
export class ExpiredTokenInterceptor implements HttpInterceptor {

  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) { }

  /** @inheritdoc */
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {

    return next.handle(req).pipe(
      catchHttpErrorResponse(error => {
        if (this.shouldHttpErrorBeIgnored(error)) {
          return throwError(() => error);
        }

        return this.userService.logout().pipe(
          tap(() => this.router.navigate(['auth'])),
          switchMap(() => next.handle(req)),
        );
      }),
    );
  }

  private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
    return error.status !== HttpStatusCode.Unauthorized;
  }
}
