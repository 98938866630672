import { Injectable } from '@angular/core';
import { UserFiltersParams, UserSortField } from '@scp/common/core/models/users-filter-params';
import { FlatProperties } from '@scp/common/core/utils/types/flat-properties';

import { BaseFilterParamsMapper } from '../base-filter-params.mapper';
import { BaseFilterParamsDto } from '../dto/base-filter-params.dto';
import { UserDto } from '../dto/user.dto';
import { IMapperToDto } from '../mappers';
import { SortMapper } from '../sort.mapper';

type UserSortFieldDto = FlatProperties<Pick<UserDto,
  | 'title'
  | 'first_name'
  | 'last_name'
  | 'role'
  | 'last_login'
>, ''>;

const USER_SORT_FIELD_MAP: Readonly<Record<UserSortField, UserSortFieldDto | UserSortFieldDto[]>> = {
  [UserSortField.FullName]: ['first_name', 'last_name'],
  [UserSortField.LastLogin]: 'last_login',
  [UserSortField.Role]: 'role',
};

/** API filters available to manage users list. */
export interface UserFilterParamsDto extends BaseFilterParamsDto.PaginationDto, BaseFilterParamsDto.SearchDto {

  /** Sort parameter. */
  readonly ordering?: string;
}

/** Users filter params mapper. */
@Injectable({
  providedIn: 'root',
})
export class UsersFilterParamsMapper
implements IMapperToDto<UserFilterParamsDto, UserFiltersParams> {

  public constructor(
    private readonly baseFilterParamsMapper: BaseFilterParamsMapper,
    private readonly sortMapper: SortMapper,
  ) {}

  /** @inheritdoc */
  public toDto(data: UserFiltersParams): UserFilterParamsDto {
    return {
      ...this.baseFilterParamsMapper.toPaginationFilterDto(data),
      ...this.baseFilterParamsMapper.toSearchFilterDto(data),
      ordering: data.sort ? this.sortMapper.toDto([data.sort], USER_SORT_FIELD_MAP) : undefined,
    };
  }
}
