import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SimpleValueAccessor, controlProviderFor } from '@scp/common/core/utils/value-accessor';

/** Simple datepicker component. */
@Component({
  selector: 'scpc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => DatepickerComponent)],
})
export class DatepickerComponent extends SimpleValueAccessor<Date | null> {

  /** Input placeholder. */
  @Input()
  public placeholder: HTMLInputElement['placeholder'] = 'MM DD YYYY';

}
