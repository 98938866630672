import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatChipEvent } from '@angular/material/chips';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FavoriteProduct } from '@scp/common/core/models/favorite-product';
import { Product } from '@scp/common/core/models/product';
import { User } from '@scp/common/core/models/user';
import { FavoriteProductService } from '@scp/common/core/services/favorite-product.service';
import { ProductFilterParamsService } from '@scp/common/core/services/product-filter-params.service';
import { UserService } from '@scp/common/core/services/user.service';
import { takeOneOrUntilDestroy } from '@scp/common/core/utils/rxjs/take-one-or-until-destroy';
import { combineLatest, distinctUntilChanged, fromEvent, map, Observable, ReplaySubject, startWith, switchMap, tap } from 'rxjs';

/** Header component for aside layout. */
@UntilDestroy()
@Component({
  selector: 'scpw-aside-layout-header',
  templateUrl: './aside-layout-header.component.html',
  styleUrls: ['./aside-layout-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLayoutHeaderComponent implements AfterViewInit, OnInit {

  /** Hidden products ref to select. */
  @ViewChild('hiddenProduct')
  protected readonly hiddenProductsRef!: MatSelect;

  /** Current user. */
  protected readonly user$: Observable<User | null>;

  /** List of products. */
  protected readonly favoriteProducts$: Observable<FavoriteProduct[]>;

  /** The number of hidden products that doesn't in viewport. */
  protected readonly hiddenFavoriteProductsCountSubject: Observable<number>;

  /** Hidden favorite product list. */
  protected readonly hiddenFavoriteProducts$: Observable<FavoriteProduct[]>;

  /** Current product. */
  protected readonly currentProduct$: Observable<Product>;

  private readonly favoriteProductsSubject = new ReplaySubject<FavoriteProduct[]>(1);

  private readonly visibleFavoriteProductsCountSubject = new ReplaySubject<number>(1);

  /** Products element reference. */
  @ViewChild('productsContainer')
  public productsElementRef: ElementRef<HTMLDivElement> | null = null;

  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly favoriteProductService: FavoriteProductService,
    private readonly productFilterService: ProductFilterParamsService,
  ) {
    this.currentProduct$ = this.productFilterService.getCurrentProduct();
    this.user$ = userService.currentUser$;

    this.favoriteProducts$ = combineLatest([
      this.favoriteProductsSubject,
      this.visibleFavoriteProductsCountSubject,
    ]).pipe(
      map(([products, _productsCount]) => products),
    );

    this.hiddenFavoriteProductsCountSubject = this.visibleFavoriteProductsCountSubject.pipe(
      switchMap(visibleCount => this.favoriteProductsSubject.pipe(
        map(products => products.length - visibleCount),
      )),
    );

    this.hiddenFavoriteProducts$ = this.hiddenFavoriteProductsCountSubject.pipe(
      switchMap(hiddenCount => this.favoriteProductsSubject.pipe(
        map(products => hiddenCount > 0 ? [...products].splice(products.length - hiddenCount) : []),
      )),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.favoriteProductService.currentList$.pipe(
      tap(favoriteProducts => this.favoriteProductsSubject.next(favoriteProducts)),
      untilDestroyed(this),
    )
      .subscribe();
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    if (this.document.defaultView && this.productsElementRef?.nativeElement) {
      const productsEl = this.productsElementRef.nativeElement;
      fromEvent(this.document.defaultView, 'resize').pipe(
        map(() => (productsEl.clientWidth - 30) / 160),
        startWith((productsEl.clientWidth - 30) / 160),
        map(count => Math.round(count)),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
        .subscribe(count => this.visibleFavoriteProductsCountSubject.next(count));
    }
  }

  /**
   * TrackBy function for products list.
   * @param _ Index.
   * @param favoriteProduct Company.
   */
  protected trackProduct(_: number, favoriteProduct: FavoriteProduct): number {
    return favoriteProduct.id;
  }

  /** Log out from account. */
  protected logOut(): void {
    this.userService.logout().pipe(
      tap(() => this.router.navigate(['auth'])),
      takeOneOrUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Handles the removal of a favorite product.
   * @param event Mat chip event.
   * @param favoriteProduct Favorite product.
   */
  protected onRemovalFavoriteProduct(event: MatChipEvent, favoriteProduct: FavoriteProduct): void {
    event.chip.disabled = true;
    this.favoriteProductService.removeProductFromFavorites(favoriteProduct.id);
  }

  /**
   * Handles favorite product selection.
   * @param product Product.
   */
  protected onProductSelected(product: Product): void {
    this.productFilterService.setCurrentProduct(product);
  }

  /** Handles hidden products open. */
  protected onOpenHiddenSelect(): void {
    this.hiddenProductsRef.open();
  }
}
