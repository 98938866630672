import { ONE_DAY_MILLISECONDS } from '../utils/date';

/** Date range. */
export class DateRange {

  /** Start date. */
  public readonly start: Date;

  /** End date. */
  public readonly end: Date;

  public constructor(dateRange: DateRangeConstructorData) {
    this.start = dateRange.start;
    this.end = dateRange.end;
  }

  /** Gets days amount in date range. */
  public get daysAmount(): number {
    return (this.start.getTime() - this.end.getTime()) / ONE_DAY_MILLISECONDS;
  }
}

type DateRangeConstructorData = Pick<DateRange, 'start' | 'end'>;
