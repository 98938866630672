import { Injectable } from '@angular/core';

import { Pagination } from '../../models/pagination';

import { PaginationDto } from './dto/pagination.dto';

import { IMapperFromDto } from './mappers';

type MapperFunction<TDto, TDomain> = (dto: TDto) => TDomain;

/** Pagination mapper. */
@Injectable({ providedIn: 'root' })
export class PaginationMapper {

  /**
   * Map pagination from dto.
   * @param page Dto page.
   * @param mapper Mapper for the items.
   */
  public fromDto<TDto, TDomain>(
    page: PaginationDto<TDto>,
    mapper: IMapperFromDto<TDto, TDomain> | MapperFunction<TDto, TDomain>,
  ): Pagination<TDomain> {
    return new Pagination({
      items: page.results.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
      totalCount: page.count,
      haveNext: !!page.next,
      havePrev: !!page.previous,
    });
  }
}
