import { IconDeclaration } from '@scp/common/core/services/icons.service';

/**
 * List of icons to register for mat-icon.
 */
export const ICONS: IconDeclaration[] = [
  {
    title: 'home',
    url: 'home.svg',
  },
  {
    title: 'calendar',
    url: 'calendar.svg',
  },
  {
    title: 'medic',
    url: 'medic.svg',
  },
  {
    title: 'physician',
    url: 'physician.svg',
  },
  {
    title: 'prescription',
    url: 'prescription.svg',
  },
  {
    title: 'angle-left',
    url: 'angle-left.svg',
  },
  {
    title: 'angle-right',
    url: 'angle-right.svg',
  },
  {
    title: 'globe',
    url: 'globe.svg',
  },
  {
    title: 'landmark',
    url: 'landmark.svg',
  },
  {
    title: 'prescription-bottle',
    url: 'prescription-bottle.svg',
  },
  {
    title: 'bell',
    url: 'bell.svg',
  },
  {
    title: 'pills',
    url: 'pills.svg',
  },
  {
    title: 'capsules',
    url: 'capsules.svg',
  },
  {
    title: '24hr',
    url: '24hr.svg',
  },
  {
    title: 'people',
    url: 'people.svg',
  },
  {
    title: 'folder',
    url: 'folder.svg',
  },
  {
    title: 'user',
    url: 'user.svg',
  },
  {
    title: 'help',
    url: 'help.svg',
  },
  {
    title: 'edit',
    url: 'edit.svg',
  },
  {
    title: 'circle-plus',
    url: 'circle-plus.svg',
  },
  {
    title: 'circle-minus',
    url: 'circle-minus.svg',
  },
  {
    title: 'info',
    url: 'info.svg',
  },
  {
    title: 'clipboard-user',
    url: 'clipboard-user.svg',
  },
  {
    title: 'clock',
    url: 'clock.svg',
  },
  {
    title: 'exclamation-triangle',
    url: 'exclamation-triangle.svg',
  },
  {
    title: 'store',
    url: 'store.svg',
  },
  {
    title: 'dashboard-settings',
    url: 'dashboard-settings.svg',
  },
  {
    title: 'add',
    url: 'add.svg',
  },
  {
    title: 'clock',
    url: 'clock.svg',
  },
  {
    title: 'download',
    url: 'download.svg',
  },
  {
    title: 'share',
    url: 'share.svg',
  },
  {
    title: 'notes-medical',
    url: 'notes-medical.svg',
  },
  {
    title: 'prior-authorization',
    url: 'prior-authorization.svg',
  },
  {
    title: 'trash',
    url: 'trash.svg',
  },
];

export const USA_ICONS: IconDeclaration[] = [
  {
    title: 'usa',
    url: 'usa-map.svg',
  },
];
