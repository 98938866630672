import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CommonSharedModule } from '@scp/common/shared/common-shared.module';

import { AsideLayoutHeaderComponent } from './aside-layout-header/aside-layout-header.component';
import { AsideLayoutSidebarComponent } from './aside-layout-sidebar/aside-layout-sidebar.component';
import { AsideLayoutComponent } from './aside-layout.component';
import { ProductsMenuComponent } from './aside-layout-sidebar/products-menu/products-menu.component';

/** Aside layout module. Provide layout with header and sidebar. */
@NgModule({
  declarations: [AsideLayoutComponent, AsideLayoutHeaderComponent, AsideLayoutSidebarComponent, ProductsMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    CommonSharedModule,
  ],
  exports: [AsideLayoutComponent],
})
export class AsideLayoutModule { }
