import { Injectable } from '@angular/core';
import { FlatProperties } from '@scp/common/core/utils/types/flat-properties';

import { PhysicianFilterParams, PhysicianSortField } from '../../models/physicians-filter-params';

import { BaseFilterParamsMapper } from './base-filter-params.mapper';
import { BaseFilterParamsDto } from './dto/base-filter-params.dto';
import { PhysicianDto } from './dto/physicians.dto';
import { IMapperToDto } from './mappers';
import { SortMapper } from './sort.mapper';

type PhysicianSortFieldDto = FlatProperties<Pick<PhysicianDto,
  | 'first_name'
  | 'middle_name'
  | 'last_name'
  | 'state'
  | 'postal_code'
  | 'fills_count'
>, ''>;

const PHYSICIAN_SORT_FIELD_MAP: Readonly<Record<PhysicianSortField, PhysicianSortFieldDto | PhysicianSortFieldDto[]>> = {
  [PhysicianSortField.Name]: ['first_name', 'middle_name', 'last_name'],
  [PhysicianSortField.State]: 'state',
  [PhysicianSortField.Zip]: 'postal_code',
  [PhysicianSortField.TotalRefills]: 'fills_count',
};

/** API filters available to manage physician list. */
export interface PhysicianFilterParamsDto extends BaseFilterParamsDto.PaginationDto, BaseFilterParamsDto.SearchDto {

  /** Sort parameter. */
  readonly ordering?: string;
}

/** Physician filter params mapper. */
@Injectable({
  providedIn: 'root',
})
export class PhysicianFilterParamsMapper
implements IMapperToDto<PhysicianFilterParamsDto, PhysicianFilterParams> {

  public constructor(
    private readonly baseFilterParamsMapper: BaseFilterParamsMapper,
    private readonly sortMapper: SortMapper,
  ) {}

  /** @inheritdoc */
  public toDto(filter: PhysicianFilterParams): PhysicianFilterParamsDto {
    return {
      ...this.baseFilterParamsMapper.toPaginationFilterDto(filter),
      ...this.baseFilterParamsMapper.toSearchFilterDto(filter),
      ordering: filter.sort ? this.sortMapper.toDto([filter.sort], PHYSICIAN_SORT_FIELD_MAP) : undefined,
    };
  }
}
