import { assertNonNull } from '../utils/assert-non-null';

/** User sort direction. */
export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

/** Sort parameters. */
export interface Sort<T> {

  /** Field to sort by. */
  readonly field: T;

  /** Sort direction. */
  readonly direction: SortDirection;
}

export namespace SortDirection {

  const MAT_SORT_DIRECTION_TO_SORT_DIRECTION: Readonly<Record<string, SortDirection>> = {
    asc: SortDirection.Ascending,
    desc: SortDirection.Descending,
  };

  /**
   * Maps `MatSortDirection` to `SortDirection`.
   * If MatSortDirection has no intersection with SortDirection there will be an error.
   * @param matSortDirection Material sort direction.
   */
  export function mapMatSortDirectionToSortDirection(matSortDirection: string): SortDirection {
    const direction = MAT_SORT_DIRECTION_TO_SORT_DIRECTION[matSortDirection];
    assertNonNull(direction);
    return direction;
  }
}
