import { Injectable } from '@angular/core';

import { Percents } from '../../models/percents';

import { Refills } from '../../models/refills';
import { RefillsTotalStats } from '../../models/refills-total-stats';

import { RefillsTotalStatsDto } from './dto/refills-total-stats.dto';

import { RefillsDto } from './dto/refills.dto';

import { IMapperFromDto } from './mappers';

/** Daily dispense mapper. */
@Injectable({
  providedIn: 'root',
})
export class RefillsMapper implements IMapperFromDto<RefillsDto, Refills> {

  /** @inheritdoc */
  public fromDto(dto: RefillsDto): Refills {
    return {
      productId: dto.product_id,
      date: new Date(dto.date),
      fillsCount: dto.fills_count,
      refillsCount: dto.refills_count,
    };
  }

  /** @inheritdoc */
  public totalStatsFromDto(dto: RefillsTotalStatsDto): RefillsTotalStats {
    return {
      todayRefillsChangePercentage: new Percents(Number(dto.today_refills_change_percentage)),
      todayRefillsCount: dto.today_refills_count,
      todayFillsChangePercentage: new Percents(Number(dto.today_fills_change_percentage)),
      todayFillsCount: dto.today_fills_count,
      totalRefillsChangePercentage: new Percents(Number(dto.total_refills_change_percentage)),
      totalRefillsCount: dto.total_refills_count,
      totalFillsChangePercentage: new Percents(Number(dto.total_fills_change_percentage)),
      totalFillsCount: dto.total_fills_count,
    };
  }
}
