import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DailyDispense } from '../models/daily-dispense';
import { Pagination } from '../models/pagination';
import { Product } from '../models/product';
import { ProductFilterParams } from '../models/product-filter-params';
import { composeHttpParams } from '../utils/compose-http-params';
import { Refills } from '../models/refills';
import { Reject } from '../models/reject';
import { BaseFilterParams } from '../models/base-filter-params';
import { RejectTotalStats } from '../models/reject-total';

import { PriorAuthorization, TotalPriorAuth } from '../models/prior-auth';

import { Physician, PhysicianDetails, TotalPhysicians } from '../models/physician';

import { RefillsTotalStats } from '../models/refills-total-stats';

import { OutstandingRefills } from '../models/outstanding-refills';

import { OutstandingRefillsTotalStats } from '../models/outstanding-refills-total-stats';

import { PhysicianFilterParams } from '../models/physicians-filter-params';

import { AppUrlsConfig } from './app-urls.config';
import { DailyDispenseMapper } from './mappers/daily-dispense.mapper';
import { DailyDispenseDto } from './mappers/dto/daily-dispense.dto';
import { PaginationDto } from './mappers/dto/pagination.dto';
import { ProductDto } from './mappers/dto/product.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { ProductFilterParamsMapper } from './mappers/product-filter-params.mapper';
import { ProductMapper } from './mappers/product.mapper';
import { RefillsDto } from './mappers/dto/refills.dto';
import { RefillsMapper } from './mappers/refills.mapper';
import { PriorAuthDto, TotalPriorAuthDto } from './mappers/dto/prior-auth.dto';
import { PriorAuthMapper } from './mappers/prior-auth.mapper';
import { RejectMapper } from './mappers/reject.mapper';
import { RejectDto } from './mappers/dto/reject.dto';
import { BaseFilterParamsMapper } from './mappers/base-filter-params.mapper';
import { RejectTotalStatsDto } from './mappers/dto/reject-total-stats.dto';
import { PhysicianMapper } from './mappers/physician.mapper';
import { PhysicianDetailsDto, PhysicianDto, TotalPhysiciansDto } from './mappers/dto/physicians.dto';
import { RefillsTotalStatsDto } from './mappers/dto/refills-total-stats.dto';
import { OutstandingRefillsDto } from './mappers/dto/outstanding-refills.dto';
import { OutstandingRefillsMapper } from './mappers/outstanding-refills.mapper';
import { OutstandingRefillsTotalStatsDto } from './mappers/dto/outstanding-refills-total-stats.dto';
import { PhysicianFilterParamsMapper } from './mappers/physicians-filters.mapper';
import { PhysicianDetailsMapper } from './mappers/physician-details.mapper';

/** Products api service. */
@Injectable({
  providedIn: 'root',
})
export class ProductsApiService {

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly dailyDispenseMapper: DailyDispenseMapper,
    private readonly refillsMapper: RefillsMapper,
    private readonly outstandingRefillsMapper: OutstandingRefillsMapper,
    private readonly rejectMapper: RejectMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly productFilterParamsMapper: ProductFilterParamsMapper,
    private readonly apiUrls: AppUrlsConfig,
    private readonly priorAuthMapper: PriorAuthMapper,
    private readonly productMapper: ProductMapper,
    private readonly baseFilterParamsMapper: BaseFilterParamsMapper,
    private readonly physicianMapper: PhysicianMapper,
    private readonly physicianDetailsMapper: PhysicianDetailsMapper,
    private readonly physicianFilterParamsMapper: PhysicianFilterParamsMapper,
  ) { }

  /**
   * Gets daily dispense list.
   * @param filter Product filter params.
   */
  public getDailyDispenseList(filter: ProductFilterParams.GeneralDateRange): Observable<Pagination<DailyDispense>> {
    const params = ProductFilterParams.getDataRangeHttpParams(this.productFilterParamsMapper.dataRangeToDto(filter));
    return this.httpClient.get<PaginationDto<DailyDispenseDto>>(this.apiUrls.products.dailyDispense, { params }).pipe(
      map(data => this.paginationMapper.fromDto(data, this.dailyDispenseMapper.fromDto)),
    );
  }

  /**
   * Gets refills list.
   * @param filter Product filter params.
   */
  public getRefillsList(filter: ProductFilterParams.GeneralDateRange): Observable<Pagination<Refills>> {
    const params = ProductFilterParams.getDataRangeHttpParams(this.productFilterParamsMapper.dataRangeToDto(filter));
    return this.httpClient.get<PaginationDto<RefillsDto>>(this.apiUrls.products.refills, { params }).pipe(
      map(data => this.paginationMapper.fromDto(data, this.refillsMapper.fromDto)),
    );
  }

  /**
   * Gets outstanding refills list.
   * @param filter Product filter params.
   */
  public getOutstandingRefillsList(filter: ProductFilterParams.GeneralDateRange): Observable<Pagination<OutstandingRefills>> {
    const params = ProductFilterParams.getDataRangeHttpParams(this.productFilterParamsMapper.dataRangeToDto(filter));
    return this.httpClient.get<PaginationDto<OutstandingRefillsDto>>(this.apiUrls.products.outstandingRefills, { params }).pipe(
      map(data => this.paginationMapper.fromDto(data, this.outstandingRefillsMapper.fromDto)),
    );
  }

  /**
   * Gets reject list.
   * @param filter Product filter params.
   */
  public getRejectList(filter: ProductFilterParams.GeneralDateRange & BaseFilterParams.Pagination): Observable<Pagination<Reject>> {
    const paginationDto = this.baseFilterParamsMapper.toPaginationFilterDto(filter);
    const dateRangeDto = this.productFilterParamsMapper.dataRangeToDto(filter);
    const params = composeHttpParams({
      ...dateRangeDto,
      ...paginationDto,
    });

    return this.httpClient.get<PaginationDto<RejectDto>>(this.apiUrls.products.reject, { params }).pipe(
      map(data => this.paginationMapper.fromDto(data, this.rejectMapper.fromDto)),
    );
  }

  /**
   * Gets refills total stats.
   * @param filter Product filter params.
   */
  public getRefillsTotalStats(filter: ProductFilterParams.GeneralDateRange & BaseFilterParams.Pagination): Observable<RefillsTotalStats> {
    const paginationDto = this.baseFilterParamsMapper.toPaginationFilterDto(filter);
    const dateRangeDto = this.productFilterParamsMapper.dataRangeToDto(filter);
    const params = composeHttpParams({
      ...dateRangeDto,
      ...paginationDto,
    });

    return this.httpClient.get<RefillsTotalStatsDto>(this.apiUrls.products.refillsTotal, { params }).pipe(
      map(data => this.refillsMapper.totalStatsFromDto(data)),
    );
  }

  /**
   * Gets reject total stats.
   * @param filter Product filter params.
   */
  public getRejectTotalStats(filter: ProductFilterParams.GeneralDateRange & BaseFilterParams.Pagination): Observable<RejectTotalStats> {
    const paginationDto = this.baseFilterParamsMapper.toPaginationFilterDto(filter);
    const dateRangeDto = this.productFilterParamsMapper.dataRangeToDto(filter);
    const params = composeHttpParams({
      ...dateRangeDto,
      ...paginationDto,
    });

    return this.httpClient.get<RejectTotalStatsDto>(this.apiUrls.products.rejectTotal, { params }).pipe(
      map(data => this.rejectMapper.totalStatsFromDto(data)),
    );
  }

  /**
   * Gets outstanding refills total stats.
   * @param filter Product filter params.
   */
  public getOutstandingRefillsTotalStats(
    filter: ProductFilterParams.GeneralDateRange & BaseFilterParams.Pagination,
  ): Observable<OutstandingRefillsTotalStats> {
    const paginationDto = this.baseFilterParamsMapper.toPaginationFilterDto(filter);
    const dateRangeDto = this.productFilterParamsMapper.dataRangeToDto(filter);
    const params = composeHttpParams({
      ...dateRangeDto,
      ...paginationDto,
    });

    return this.httpClient.get<OutstandingRefillsTotalStatsDto>(this.apiUrls.products.outstandingRefillsTotal, { params }).pipe(
      map(data => this.outstandingRefillsMapper.totalStatsFromDto(data)),
    );
  }

  /** Gets products. */
  public getProducts(): Observable<Pagination<Product>> {
    return this.httpClient.get<PaginationDto<ProductDto>>(this.apiUrls.products.list)
      .pipe(
        map(pagination => this.paginationMapper.fromDto<ProductDto, Product>(
          pagination,
          userDto => this.productMapper.fromDto(userDto),
        )),
      );
  }

  /**
   * Get prior authorization list.
   * @param filter Product filter params.
   */
  public getPriorAuthList(
    filter: ProductFilterParams.GeneralDateRange & BaseFilterParams.Pagination,
  ): Observable<Pagination<PriorAuthorization>> {
    const paginationDto = this.baseFilterParamsMapper.toPaginationFilterDto(filter);
    const dateRangeDto = this.productFilterParamsMapper.dataRangeToDto(filter);
    const params = composeHttpParams({
      ...dateRangeDto,
      ...paginationDto,
    });

    return this.httpClient.get<PaginationDto<PriorAuthDto>>(this.apiUrls.products.priorAuth, { params }).pipe(
      map(data => this.paginationMapper.fromDto(data, this.priorAuthMapper.fromDto)),
    );
  }

  /** Get total prior authorization data. */
  public getTotalPriorAuth(): Observable<TotalPriorAuth> {
    return this.httpClient.get<TotalPriorAuthDto>(this.apiUrls.products.totalPriorAuth).pipe(
      map(data => this.priorAuthMapper.totalStatsFromDto(data)),
    );
  }

  /**
   * Get physician list.
   * @param filterParams Physician filter params.
   */
  public getPhysicianList(
    filterParams: PhysicianFilterParams,
  ): Observable<Pagination<Physician>> {
    const params = composeHttpParams(
      this.physicianFilterParamsMapper.toDto(filterParams),
    );
    return this.httpClient.get<PaginationDto<PhysicianDto>>(this.apiUrls.products.physicians, { params }).pipe(
      map(data => this.paginationMapper.fromDto(data, this.physicianMapper.fromDto)),
    );
  }

  /** Get total prior authorization data. */
  public getTotalPhysicians(): Observable<TotalPhysicians> {
    return this.httpClient.get<TotalPhysiciansDto>(this.apiUrls.products.totalPhysicians).pipe(
      map(data => this.physicianMapper.totalStatsFromDto(data)),
    );
  }

  /**
   * Get physician details by id.
   * @param id Id.
   */
  public getPhysicianDetailsById(id: string): Observable<PhysicianDetails> {
    return this.httpClient.get<PhysicianDetailsDto>(this.apiUrls.products.physicianDetails(id)).pipe(
      map(data => this.physicianDetailsMapper.fromDto(data)),
    );
  }
}
