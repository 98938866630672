import { enumToArray } from '../utils/enum-to-array';

/** Available user roles. */
export enum UserRole {

  /** Admin user. Person from the client company. */
  SuperAdmin = 'super_admin',

  /** Admin of some organization that bought access to the app. */
  OrgAdmin = 'org_admin',

  /** Standard user, part of an organization. */
  User = 'user',
}

/**
 * UserRole utility functions.
 */
export namespace UserRole {

  const TO_READABLE_MAP: Record<UserRole, string> = {
    [UserRole.OrgAdmin]: 'Organization Admin',
    [UserRole.SuperAdmin]: 'Super-Admin',
    [UserRole.User]: 'Standard User',
  };

  /**
   * Respresent UserRole enum as an array.
   */
  export function toArray(): UserRole[] {
    return enumToArray(UserRole);
  }

  /**
   * Provide human-readable value for UserRole member.
   * @param role UserRole member.
   */
  export function toReadable(role: UserRole): string {
    return TO_READABLE_MAP[role];
  }
}
