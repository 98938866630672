import { Injectable } from '@angular/core';

import { AppError, EntityValidationErrors } from '../../models/app-error';

import { User, UserCreationData } from '../../models/user';
import { UserRole } from '../../models/user-role';
import { UserTitle } from '../../models/user-title';
import { reverseRecord } from '../../utils/reverse-record';

import { UserRoleDto } from './dto/user-role.dto';
import { UserTitleDto } from './dto/user-title.dto';

import { UserCreationDto, UserDto } from './dto/user.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessageByField } from './extract-error-message';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';

const USER_ROLE_FROM_DTO_MAP: Readonly<Record<UserRoleDto, UserRole>> = {
  [UserRoleDto.OrganizationAdmin]: UserRole.OrgAdmin,
  [UserRoleDto.SuperAdmin]: UserRole.SuperAdmin,
  [UserRoleDto.User]: UserRole.User,
};

const USER_ROLE_TO_DTO = reverseRecord(USER_ROLE_FROM_DTO_MAP);

const USER_TITLE_FROM_DTO_MAP: Readonly<Record<UserTitleDto, UserTitle>> = {
  [UserTitleDto.Doctor]: UserTitle.Dr,
  [UserTitleDto.Mister]: UserTitle.Mister,
  [UserTitleDto.Sir]: UserTitle.Sir,
  [UserTitleDto.Ms]: UserTitle.Ms,
};

const USER_TITLE_TO_DTO = reverseRecord(USER_TITLE_FROM_DTO_MAP);

/** User mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserMapper implements
  IMapperFromDto<UserDto, User>,
  IMapperToDto<UserCreationDto, UserCreationData>,
  IValidationErrorMapper<UserCreationDto, UserCreationData> {

  /** @inheritdoc */
  public fromDto(userDto: UserDto): User {
    const organization = userDto.organization_data != null ? {
      id: userDto.organization_data.id,
      name: userDto.organization_data.name,
    } : null;

    return {
      firstName: userDto.first_name,
      lastName: userDto.last_name,
      id: userDto.id,
      avatarUrl: userDto.avatar,
      canExportData: userDto.is_data_export_available,
      email: userDto.email,
      isActive: userDto.is_active,
      lastLogin: userDto.last_login ? new Date(userDto.last_login) : null,
      organization,
      role: USER_ROLE_FROM_DTO_MAP[userDto.role],
      title: USER_TITLE_FROM_DTO_MAP[userDto.title],
      jobTitle: userDto.job_title,
      created: new Date(userDto.created),
    };
  }

  /** @inheritdoc */
  public toDto(data: UserCreationData): UserCreationDto {
    if (data.avatar instanceof File) {
      throw new AppError('Avatar file object should be uploaded to s3 and passed as URL');
    }

    return {
      avatar: data.avatar,
      email: data.email,
      first_name: data.firstName,
      is_data_export_available: data.canExportData,
      last_name: data.lastName,
      organization_id: data.organization?.id ?? null,
      role: USER_ROLE_TO_DTO[data.role],
      title: USER_TITLE_TO_DTO[data.title],
      is_active: data.isActive,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<UserCreationDto>): EntityValidationErrors<UserCreationData> {
    return {
      avatar: extractErrorMessageByField('avatar', errorDto),
      email: extractErrorMessageByField('email', errorDto),
    };
  }
}
