import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@scp/common/core/models/user';
import { UserTitle } from '@scp/common/core/models/user-title';

/** Full name user pipe. */
@Pipe({ name: 'userFullName' })
export class UserFullNamePipe implements PipeTransform {

  /**
   * Returns the user's full name with a prefix.
   * @param user Object of user.
   */
  public transform(user: User): string {
    return `${UserTitle.toReadable(user.title)} ${user.firstName} ${user.lastName}`;
  }

}
