import { BaseFilterParams } from './base-filter-params';
import { Sort } from './sort';

/** User sort field.  */
export enum UserSortField {
  FullName = 'name',
  Role = 'role',
  LastLogin = 'lastLogin',
}

/** User sort.  */
export type UsersSort = Sort<UserSortField>;

/** User filter params. */
export interface UserFiltersParams extends BaseFilterParams.Combined {

  /** Sort. */
  readonly sort?: Sort<UserSortField>;
}
