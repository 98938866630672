export const DEFAULT_PERCENT_VALUE = 0;

/** Represent a numeric value as a percent. 0 = 0%, 1 = 100%. */
export class Percents {

  /** Floating point number (1 = 100%). */
  public readonly valueProportion: number;

  /** Percent value number (100 = 100%). */
  public readonly valuePercent: number;

  public constructor(percents?: number) {
    this.valueProportion = percents != null ? percents / 100 : DEFAULT_PERCENT_VALUE;
    this.valuePercent = percents ?? DEFAULT_PERCENT_VALUE;
  }

}
