import { ChangeDetectionStrategy, Component } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@scp/common/core/utils/value-accessor';

/**
 * Toggle component.
 */
@Component({
  selector: 'scpc-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => ToggleComponent)],
})
export class ToggleComponent extends SimpleValueAccessor<boolean> {

  /**
   * Handle "click" of the toggle button.
   */
  protected onToggleClick(): void {
    this.controlValue = !this.controlValue;
  }
}
