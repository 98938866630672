import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '@scp/common/core/services/navigation.service';

/**  Back button directive. */
@Directive({ selector: '[scpcBackButton]' })
export class BackButtonDirective {
  public constructor(private readonly navigation: NavigationService) { }

  /** Handles going to the previous page. */
  @HostListener('click')
  public onGoToPreviousPage(): void {
    this.navigation.back();
  }
}
