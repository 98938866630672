<scpw-aside-layout-header class="header"></scpw-aside-layout-header>

<section class="sidebar-container">
  <scpw-aside-layout-sidebar
    (scpcClickOutside)="onSidebarClose()"
    class="sidebar"
    [isProductsMenuOpened]="isProductListOpened"
    [isOpened]="isSidebarOpened"
    (openStateChange)="setSidebarOpened($event)"
    (productsMenuOpenStateChange)="setProductListOpened($event)"
  ></scpw-aside-layout-sidebar>
  <main class="content" [class.content_blur]="isSidebarOpened">
    <router-outlet></router-outlet>
  </main>
</section>
