import { enumToArray } from '../utils/enum-to-array';

/** Available user roles. */
export enum UserTitle {
  None = '',
  Dr = 'doctor',
  Sir = 'sir',
  Mister = 'mister',
  Ms = 'ms',
}

/** UserRole utility functions. */
export namespace UserTitle {

  const TO_READABLE: Record<UserTitle, string> = {
    [UserTitle.None]: '',
    [UserTitle.Dr]: 'Dr.',
    [UserTitle.Sir]: 'Sir',
    [UserTitle.Mister]: 'Mr.',
    [UserTitle.Ms]: 'Ms.',
  };

  /** Respresent UserRole enum as an array. */
  export function toArray(): UserTitle[] {
    return enumToArray(UserTitle);
  }

  /**
   * Provide human-readable value for UserRole member.
   * @param role UserRole member.
   */
  export function toReadable(role: UserTitle): string {
    return TO_READABLE[role];
  }
}
