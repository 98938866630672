<div class="scpc-range-datepicker">
  <mat-date-range-input [rangePicker]="picker" [formGroup]="formGroup">
    <input
      matStartDate
      placeholder="MM DD YYYY"
      formControlName="start"
      data-type="datepicker"
    />
    <input
      matEndDate
      placeholder="MM DD YYYY"
      formControlName="end"
      data-type="datepicker"
    />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</div>
