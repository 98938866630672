import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/** Date with placeholder pipe. */
@Pipe({ name: 'dateWithPlaceholder' })
export class DateWithPlaceholderPipe implements PipeTransform {

  public constructor(private readonly datePipe: DatePipe) { }

  /**
   * Returns the formatted date if it is not null otherwise placeholder.
   * @param date Original date.
   * @param format Date format.
   * @param placeholder Placeholder.
   */
  public transform(date: Date | null | undefined, format = 'short', placeholder = 'None'): string | null {
    return date != null ? this.datePipe.transform(date, format) : placeholder;
  }

}
