/** Report frequency period. */
export enum ReportFrequencyPeriod {
  None = '',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

/** Report frequency. */
export interface ReportFrequency {

  /** Frequency period (for example, daily, weekly, etc.). */
  readonly period: ReportFrequencyPeriod;

  /** Day of month (if type is 'monthly'). */
  readonly dayOfMonth: number | null;

  /** Day of week (if type is 'weekly'). */
  readonly dayOfWeek: number | null;
}
