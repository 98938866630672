import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartDatasetInfo } from '@scp/common/core/models/chart-dataset-info';
import { ChartData, ChartOptions, Plugin } from 'chart.js';

/** Prior auth doughnut chart component. */
@Component({
  selector: 'scpc-prior-auth-doughnut-chart',
  templateUrl: './prior-auth-doughnut-chart.component.html',
  styleUrls: ['./prior-auth-doughnut-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorAuthDoughnutChartComponent implements OnInit {

  /** Chart info. */
  @Input()
  public chartInfo!: ChartDatasetInfo;

  /** Dataset background color params for chart render. */
  @Input()
  public backgroundColor: string[] = [];

  /** Chart data. */
  protected data!: ChartData;

  /** Chart options. */
  protected options!: ChartOptions;

  /** Plugins. */
  protected chartPlugins!: Plugin[];

  public constructor() {
    this.options = this.getChartOptions();
    this.chartPlugins = [this.showValueInCenterPlugin];
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.data = this.getChartData();
  }

  private getChartData(): ChartData {
    return {
      datasets: [
        {
          data: this.chartInfo.values,
          backgroundColor: this.backgroundColor,
        },
      ],
      labels: [...this.chartInfo.labels],
    };
  }

  /** Custom plugin to show value in the center of doughnut. */
  private showValueInCenterPlugin: Plugin = {
    id: 'valueInCenter',
    afterDatasetsDraw(chart, _args, _opts) {
      const { ctx, chartArea: { top, width, height } } = chart;
      ctx.save();
      const color = chart.config.data.datasets[0].backgroundColor instanceof Array ?
        chart.config.data.datasets[0].backgroundColor[0] as string :
        'black';
      const value = chart.config.data.datasets[0].data[0];
      ctx.font = 'bold 14px Rubik';
      ctx.fillStyle = color;
      ctx.textAlign = 'center';
      ctx.fillText(`${value}%`, width / 2, height / 2 + top);
    },
  };

  private getChartOptions(): ChartOptions<'doughnut'> {
    return {
      plugins: {
        legend: {
          onClick: event => event.native?.preventDefault(),
          align: 'center',
          position: 'bottom',
          display: true,
        },
      },
      cutout: '65%',
      responsive: true,
      maintainAspectRatio: false,
    };
  }
}
