import { untilDestroyed } from '@ngneat/until-destroy';
import { MonoTypeOperatorFunction } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Apply take(1) and untilDestroy() operators to a stream.
 * @param instance Instance to track destroy hook.
 */
export function takeOneOrUntilDestroy<P, T>(instance: T): MonoTypeOperatorFunction<P> {
  return source$ => source$.pipe(
    take(1),
    untilDestroyed(instance),
  );
}
