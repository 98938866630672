import { Injectable } from '@angular/core';

import { FavoriteProduct } from '../../models/favorite-product';

import { FavoriteProductDto } from './dto/favorite-product.dto';
import { IMapperFromDto } from './mappers';
import { ProductMapper } from './product.mapper';

/**  Favorite product mapper. */
@Injectable({ providedIn: 'root' })
export class FavoriteProductMapper implements IMapperFromDto<FavoriteProductDto, FavoriteProduct> {

  public constructor(
    private readonly productMapper: ProductMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(favoriteProductDto: FavoriteProductDto): FavoriteProduct {
    return {
      id: favoriteProductDto.id,
      product: this.productMapper.fromDto(favoriteProductDto.product_data),
    };
  }

}
