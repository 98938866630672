import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/** Tracks a click not on the current element. */
@Directive({
  selector: '[scpcClickOutside]',
})
export class ClickOutsideDirective {

  public constructor(private readonly elementRef: ElementRef) { }

  /** Emitter for outside click. */
  @Output('scpcClickOutside')
  public readonly clickOutside = new EventEmitter<void>();

  /**
   * Handles `click` on element.
   * @param targetElement Target element.
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }

}
