import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Pagination } from '../models/pagination';
import { User, UserCreationData } from '../models/user';
import { UserFiltersParams } from '../models/users-filter-params';
import { composeHttpParams } from '../utils/compose-http-params';

import { AppUrlsConfig } from './app-urls.config';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { PaginationDto } from './mappers/dto/pagination.dto';
import { UserDto } from './mappers/dto/user.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { UserMapper } from './mappers/user.mapper';
import { UsersFilterParamsMapper } from './mappers/users/user-filters.mapper';

/** Users api service. */
@Injectable({ providedIn: 'root' })
export class UsersApiService {

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly apiUrls: AppUrlsConfig,
    private readonly paginationMapper: PaginationMapper,
    private readonly userMapper: UserMapper,
    private readonly userFilterParamsMapper: UsersFilterParamsMapper,
    private readonly appErrorMapper: AppErrorMapper,
  ) { }

  /** Returns current user info.*/
  public getCurrentUser(): Observable<User> {
    return this.httpClient
      .get<UserDto>(this.apiUrls.user.currentProfile)
      .pipe(map(user => this.userMapper.fromDto(user)));
  }

  /**
   * Fetches users.
   * @param queryParams Parameters for a request.
   */
  public getUsers(queryParams: UserFiltersParams): Observable<Pagination<User>> {
    const params = composeHttpParams(
      this.userFilterParamsMapper.toDto(queryParams),
    );

    return this.httpClient.get<PaginationDto<UserDto>>(this.apiUrls.users.list, { params })
      .pipe(
        map(pagination => this.paginationMapper.fromDto<UserDto, User>(
          pagination,
          userDto => this.userMapper.fromDto(userDto),
        )),
      );
  }

  /**
   * Create a user and returns new user instance.
   * @param user Data to create a user.
   */
  public create(user: UserCreationData): Observable<User> {
    return this.saveUser(user, this.apiUrls.users.list);
  }

  /**
   * Update existing user.
   * @param userId User ID to update.
   * @param user Data for update.
   */
  public update(userId: User['id'], user: UserCreationData): Observable<User> {
    const url = this.apiUrls.users.entity(userId);

    return this.saveUser(user, url);
  }

  private saveUser(user: UserCreationData, url: string): Observable<User> {
    const body = this.userMapper.toDto(user);

    const request$ = user.id ?
      this.httpClient.put<UserDto>(url, body) :
      this.httpClient.post<UserDto>(url, body);

    return request$.pipe(
      map(dto => this.userMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.userMapper),
    );
  }

}
