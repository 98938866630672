import { Injectable } from '@angular/core';

import { Percents } from '../../models/percents';

import { Reject } from '../../models/reject';
import { RejectTotalStats } from '../../models/reject-total';

import { RejectTotalStatsDto } from './dto/reject-total-stats.dto';

import { RejectDto } from './dto/reject.dto';
import { IMapperFromDto } from './mappers';

/** Reject mapper mapper. */
@Injectable({
  providedIn: 'root',
})
export class RejectMapper implements IMapperFromDto<RejectDto, Reject> {

  /** @inheritdoc */
  public fromDto(dto: RejectDto): Reject {
    return {
      productId: dto.product_id,
      date: new Date(dto.date),
      abandonedCount: dto.abandoned_count,
      rejectedCount: dto.rejected_count,
    };
  }

  /** @inheritdoc */
  public totalStatsFromDto(dto: RejectTotalStatsDto): RejectTotalStats {
    return {
      todayAbandonedChangePercentage: new Percents(Number(dto.today_abandoned_change_percentage)),
      todayAbandonedCount: dto.today_abandoned_count,
      todayRejectedChangePercentage: new Percents(Number(dto.today_rejected_change_percentage)),
      todayRejectedCount: dto.today_rejected_count,
      totalAbandonedChangePercentage: new Percents(Number(dto.total_abandoned_change_percentage)),
      totalAbandonedCount: dto.total_abandoned_count,
      totalRejectedChangePercentage: new Percents(Number(dto.total_rejected_change_percentage)),
      totalRejectedCount: dto.total_rejected_count,
    };
  }
}
