import { Injectable } from '@angular/core';

import { Percents } from '../../models/percents';
import { PriorAuthorization, TotalPriorAuth } from '../../models/prior-auth';

import { PriorAuthDto, TotalPriorAuthDto } from './dto/prior-auth.dto';
import { IMapperFromDto } from './mappers';

/** Prior authorization mapper. */
@Injectable({ providedIn: 'root' })
export class PriorAuthMapper implements IMapperFromDto<PriorAuthDto, PriorAuthorization> {

  /** @inheritdoc */
  public fromDto(priorAuthDto: PriorAuthDto): PriorAuthorization {
    return {
      id: priorAuthDto.id,
      productId: priorAuthDto.product_id,
      date: new Date(priorAuthDto.date),
      withPercent: new Percents(Number(priorAuthDto.with_prior_authorization_percentage)),
      withoutPercent: new Percents(Number(priorAuthDto.without_prior_authorization_percentage)),
    };
  }

  /** @inheritdoc */
  public totalStatsFromDto(totalPriorAuthDto: TotalPriorAuthDto): TotalPriorAuth {
    return {
      totalWithPercent: new Percents(Number(totalPriorAuthDto.total_with_prior_authorization_percentage)),
      totalWithoutPercent: new Percents(Number(totalPriorAuthDto.total_without_prior_authorization_percentage)),
    };
  }

}
