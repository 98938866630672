<div
  class="panel"
  [class.panel_opened]="isOpened"
  [class.panel_collapsible]="isCollapsible"
>
  <div class="panel-heading" (click)="onPanelClick()">
    <p class="panel-heading__title">{{ panelTitle }}</p>
    <mat-icon
      class="panel-heading__icon"
      [svgIcon]="isOpened ? 'circle-minus' : 'circle-plus'"
    ></mat-icon>
  </div>
  <div [@expandCollapse]="isOpened ? 'expanded' : 'collapsed'">
    <div class="panel-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
