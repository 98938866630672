import { Provider } from '@angular/core';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';

export const materialProviders: Provider[] = [
  {
    provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
];
