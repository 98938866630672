import { ObjectPaths } from '../../utils/types/object-paths';

import { ValidationErrorDto } from './dto/validation-error.dto';

type NoneFieldError = 'non_field_errors';

/**
 * Gets error by field.
 * @param field Form field.
 * @param errorData Error data.
 * @returns The first item if error data is a array of error messages.
 */
export function extractErrorMessageByField<T>(
  field: ObjectPaths<T, 3> | NoneFieldError,
  errorData?: ValidationErrorDto<T> | string[] | null,
): string | undefined {
  if (errorData == null) {
    return undefined;
  }

  if (Array.isArray(errorData)) {
    return extractErrorMessageFromArray(errorData);
  }

  const { errors } = errorData;

  const errorsByField = errors.filter(error => error.attr === field);
  if (errorsByField.length === 0) {
    return undefined;
  }

  return errorsByField[0].detail;
}

/**
 * Extracts a string error from an array of errors.
 * @param errors Errors array.
 * @returns Extracted error string.
 */
function extractErrorMessageFromArray(errors: readonly string[]): string {
  if (typeof errors[0] !== 'string') {
    throw new Error('Got invalid error data, unable to map it');
  }

  return errors[0];
}
