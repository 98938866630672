import { Injectable } from '@angular/core';

import { ProductFilterParams } from '../../models/product-filter-params';

import { ProductFilterParamsDto } from './dto/product-filter-params.dto';

/** Product filter params mapper. */
@Injectable({ providedIn: 'root' })
export class ProductFilterParamsMapper {

  /**
   * Maps model to dto.
   * @param model Model.
   */
  public productIdToDto(model: ProductFilterParams.General): ProductFilterParamsDto.ProductId {
    return {
      product_id: model.productId,
    };
  }

  /**
   * Maps model to dto.
   * @param model Model.
   */
  public dataRangeToDto(model: ProductFilterParams.GeneralDateRange): ProductFilterParamsDto.ProductDateRange {
    return {
      date__lte: model.dateRange.end.toISOString(),
      date__gte: model.dateRange.start.toISOString(),
    };
  }
}
