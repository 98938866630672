import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, inject, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, tap } from 'rxjs';

import { Product } from '@scp/common/core/models/product';
import { ProductFilterParamsService } from '@scp/common/core/services/product-filter-params.service';
import { UserService } from '@scp/common/core/services/user.service';
import { ProductService } from '@scp/common/core/services/product.service';

/** Item of navigation menu. */
interface NavItem {

  /** Title. */
  readonly title: string;

  /** Icon URL. */
  readonly icon: string;

  /** Navigation URL. */
  readonly routerLink: string | string[];
}

const NAV_ITEMS: ReadonlyArray<NavItem> = [
  {
    title: 'Dashboard',
    icon: 'home',
    routerLink: 'dashboard',
  },
  {
    title: 'National Dispensing Rate',
    icon: 'globe',
    routerLink: 'national-dispense',
  },
  {
    title: 'Daily Dispense',
    icon: '24hr',
    routerLink: 'daily-dispense-trend',
  },
  {
    title: 'Physicians',
    icon: 'medic',
    routerLink: 'physicians',
  },
  {
    title: 'Fills / Refills',
    icon: 'capsules',
    routerLink: 'fills-refills',
  },
  {
    title: 'Rejection / Abandonments',
    icon: 'pills',
    routerLink: 'reject',
  },
  {
    title: 'Outstanding Refills',
    icon: 'bell',
    routerLink: 'outstanding-refills',
  },
  {
    title: 'Prior Authorization',
    icon: 'landmark',
    routerLink: 'prior-auth',
  },
];

/** Sidebar for aside layout. */
@UntilDestroy()
@Component({
  selector: 'scpw-aside-layout-sidebar',
  templateUrl: './aside-layout-sidebar.component.html',
  styleUrls: ['./aside-layout-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideLayoutSidebarComponent {

  /** Is sidebar expanded. */
  @Input()
  public isOpened = false;

  /**
   * Emits a value when sidebar expands / collapses.
   * It will be "true" - if sidebar expands and "false" - otherwise.
   */
  @Output()
  public readonly openStateChange = new EventEmitter<boolean>();

  /**
   * Emits a value when product expands / collapses.
   * It will be "true" - if product expands and "false" - otherwise.
   */
  @Output()
  public readonly productsMenuOpenStateChange = new EventEmitter<boolean>();

  /** Sidebar body element reference. */
  @ViewChild('sidebarBody')
  public sidebarBodyElementRef: ElementRef<HTMLDivElement> | null = null;

  /** Is products menu opened. */
  @Input()
  public isProductsMenuOpened = false;

  /** Total products count. */
  protected totalProductCount = 0;

  /** Is product menu short. */
  protected isProductMenuShort = true;

  /** Navigation items to show in the nav list. */
  protected readonly navigationItems = NAV_ITEMS;

  /** Current user. */
  protected readonly user$ = inject(UserService).currentUser$;

  /** Product list. */
  protected readonly products$ = new ReplaySubject<Product[]>(1);

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly productFilterService: ProductFilterParamsService,
    productsService: ProductService,
  ) {
    const setProductList$ = productsService.getProducts()
      .pipe(
        tap(({ items, totalCount }) => {
          this.totalProductCount = totalCount;
          this.products$.next(items);
          this.updateProductMenuSize();
        }),
      );

    setProductList$.pipe(

      // Filter by product ID should be.
      // Since all requests to get graph data must have a `product_id` field.
      tap(({ items }) => {
        this.productFilterService.setCurrentProduct(items[0]);
      }),
      untilDestroyed(this),
    )
      .subscribe();
  }

  /**
   * Handle "click" of the toggle button.
   * @param event Click event.
   */
  public onSidebarToggle(event: Event): void {
    event.stopPropagation();
    this.openStateChange.emit(!this.isOpened);
  }

  /** Handle "click" of the products sidebar toggle button. */
  public onProductsMenuToggle(): void {
    this.productsMenuOpenStateChange.emit(!this.isProductsMenuOpened);
  }

  /**
   * TrackBy function for navigation items list.
   * @param _ Index.
   * @param navItem Nav item.
   */
  protected trackNavItem(_: number, navItem: NavItem): string {
    return navItem.title;
  }

  private updateProductMenuSize(): void {
    if (!this.document.defaultView || !this.sidebarBodyElementRef?.nativeElement) {
      return;
    }

    const ICON_HEIGHT_PX = 40;
    const productsEl = this.sidebarBodyElementRef.nativeElement;
    const productElementsCount = Math.floor(productsEl.clientHeight / ICON_HEIGHT_PX);
    const DEFAULT_NAV_ELEMENTS_COUNT = 8;
    const availableElements = productElementsCount - DEFAULT_NAV_ELEMENTS_COUNT;

    if (this.totalProductCount <= availableElements) {
      this.isProductsMenuOpened = false;
    }

    this.isProductMenuShort = this.totalProductCount <= availableElements;
  }

}
