import { Injectable } from '@angular/core';

import { Sort, SortDirection } from '../../models/sort';

import { SortDirectionDto } from './dto/sort-direction.dto';

/** Mapper for sort options. */
@Injectable({
  providedIn: 'root',
})
export class SortMapper {
  /**
   * Mapper of sort to dto.
   * @param sorts Sort options.
   * @param map Mapper for sort fields to the ones that are backend-acceptable.
   */
  public toDto<TDomain extends string, TDto extends string>(
    sorts: Sort<TDomain>[],
    map: Record<TDomain, TDto | TDto[]>,
  ): string {
    return sorts
      .map(sort => {
        const prefix = sort.direction === SortDirection.Ascending ? SortDirectionDto.Ascending : SortDirectionDto.Descending;
        const field = map[sort.field];
        if (Array.isArray(field)) {
          return `${prefix}${field.join(`,${prefix}`)}`;
        }
        return `${prefix}${map[sort.field]}`;
      })
      .join(',');
  }
}
