import { Sort } from './sort';
import { BaseFilterParams } from './base-filter-params';

/** Physician sort field.  */
export enum PhysicianSortField {
  Name = 'name',
  State = 'state',
  Zip = 'zip',
  TotalRefills = 'total-refills',
}

/** Physician filter params. */
export interface PhysicianFilterParams extends BaseFilterParams.Combined {

  /** Sort. */
  readonly sort: Sort<PhysicianSortField>;

}
