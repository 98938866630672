<header class="header">
  <a [routerLink]="['dashboard']">
    <img src="/assets/logotype.png" alt="" class="header__logo" />
  </a>
  <section class="header__products products" #productsContainer>
    <mat-chip-listbox
      class="products__chips"
      *ngIf="currentProduct$ | async as currentProduct"
    >
      <mat-chip-option
        *ngFor="
          let favoriteProduct of favoriteProducts$ | async;
          trackBy: trackProduct
        "
        class="products__item"
        (removed)="onRemovalFavoriteProduct($event, favoriteProduct)"
        (selectionChange)="onProductSelected(favoriteProduct.product)"
        [class.selected]="favoriteProduct.product.id === currentProduct.id"
        color="primary"
        [title]="favoriteProduct.product.name"
      >
        <div class="products__logo logo">
          <img
            class="logo__image"
            [src]="favoriteProduct.product.imageUrl"
            [alt]="favoriteProduct.product.name"
          />
        </div>

        <button type="button" matChipRemove class="products__item-button">
          <mat-icon class="products__item-icon">cancel</mat-icon>
        </button>
      </mat-chip-option>
    </mat-chip-listbox>
    <ng-container
      *ngIf="hiddenFavoriteProductsCountSubject | async as hiddenCount"
    >
      <span
        class="products__hidden-counter"
        *ngIf="hiddenCount >= 0"
        (click)="onOpenHiddenSelect()"
      >
        +{{ hiddenCount }}
        <mat-select
          #hiddenProduct
          class="scpc-select products__hidden-item"
          panelClass="min-width-max-content"
        >
          <mat-option
            *ngFor="
              let hiddenFavoriteProducts of hiddenFavoriteProducts$ | async;
              trackBy: trackProduct
            "
            (click)="onProductSelected(hiddenFavoriteProducts.product)"
          >
            {{ hiddenFavoriteProducts.product.name }}
          </mat-option>
        </mat-select>
      </span>
    </ng-container>
  </section>
  <section *ngIf="user$ | async as user" class="header__profile">
    <button
      [matMenuTriggerFor]="menu"
      class="header__profile-button profile"
      type="button"
    >
      <img
        class="profile__image"
        [src]="user.avatarUrl | userAvatarWithPlaceholder"
        alt=""
      />
      {{ user.firstName }} {{ user.lastName }}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </section>
</header>

<mat-menu #menu="matMenu">
  <div class="menu">
    <a class="menu__item" routerLink="#">
      <mat-icon svgIcon="user"></mat-icon>
      <span>Edit my Profile</span>
    </a>
    <a class="menu__item" [routerLink]="['reports']">
      <mat-icon svgIcon="folder"></mat-icon>
      <span>Report management</span>
    </a>
    <a class="menu__item" [routerLink]="['users']">
      <mat-icon svgIcon="people"></mat-icon>
      <span>User Management</span>
    </a>
    <a class="menu__item" routerLink="#">
      <mat-icon svgIcon="help"></mat-icon>
      <span>Help &#38; Support</span>
    </a>
    <button class="menu__item" type="button" (click)="logOut()">
      <mat-icon svgIcon="globe"></mat-icon>
      <span>Log Out</span>
    </button>
  </div>
</mat-menu>
