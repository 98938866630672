import { Injectable } from '@angular/core';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
  public constructor(
    private readonly appConfigService: AppConfig,
  ) { }

  /** Auth-related routes. */
  public readonly auth = {
    login: this.toApi('auth/login/'),
    resetPassword: this.toApi('auth/password-reset/'),
    confirmPasswordReset: this.toApi('auth/password-reset-confirm/'),
  } as const;

  /** Routes for getting/editing current user's info. */
  public readonly user = {
    currentProfile: this.toApi('users/current/'),
    changePassword: this.toApi('users/change_password/'),
  } as const;

  /** Routes to manage users in the app. */
  public readonly users = {
    list: this.toApi('users/'),
    entity: (id: string | number) => this.toApi(`users/${id}/`),
  } as const;

  /** URLs of external storage endpoints. */
  public readonly storage = {
    getParams: this.toApi('s3direct/get-params/'),
  };

  /** Routes to manage organizations. */
  public readonly organizations = {
    list: this.toApi('organizations/'),
  };

  /** Routes to manage reports. */
  public readonly reports = {
    types: this.toApi('reports/types/'),
    list: this.toApi('reports/'),
    logs: (id: string | number) => this.toApi(`reports/${id}/logs/`),
    entity: (id: string | number) => this.toApi(`reports/${id}/`),
    unsubscribe: (token: string) => this.toApi(`reports/unsubscribe/${token}/`),
  };

  /** Routes for getting products statistics. */
  public readonly products = {
    reject: this.toApi('products/rejections-abandonments/'),
    rejectTotal: this.toApi('products/rejections-abandonments/total-stats/'),
    dailyDispense: this.toApi('products/daily-dispenses/'),
    nationalDispense: this.toApi('products/national-dispenses/'),
    stateStatistics: this.toApi('products/national-dispenses/detailed-table/'),
    nationalDispenseByState: this.toApi('products/national-dispenses/by-state/'),
    nationalDispenseByCity: this.toApi('products/national-dispenses/by-city/'),
    nationalDispenseByPostal: this.toApi('products/national-dispenses/by-postal-code/'),
    nationalDispenseTotalStats: this.toApi('products/national-dispenses/total-stats/'),
    refills: this.toApi('products/refills/'),
    refillsTotal: this.toApi('products/refills/total-stats/'),
    list: this.toApi('products/products/'),
    outstandingRefills: this.toApi('products/outstanding-refills/'),
    outstandingRefillsTotal: this.toApi('products/outstanding-refills/total-stats/'),
    priorAuth: this.toApi('products/prior-authorizations/'),
    totalPriorAuth: this.toApi('products/prior-authorizations/total-stats/'),
    physicians: this.toApi('products/physicians/'),
    physicianDetails: (id: string | number) => this.toApi(`products/physicians/${id}/`),
    totalPhysicians: this.toApi('products/physicians/total-stats/'),
  } as const;

  /** Routes for getting/creating/deleting favorite products.  */
  public readonly favoriteProducts = {
    list: this.toApi('users/favorites-products/'),
    entity: (id: number) => this.toApi(`users/favorites-products/${id}/`),
  } as const;

  /** Routes for getting/updating dashboard settings.  */
  public readonly dashboardSettings = {
    entity: this.toApi(`users/dashboard-settings/`),
  } as const;

  /**
   * Checks whether the url is application-scoped.
   * @param url Url to check.
   */
  public isApplicationUrl(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl);
  }

  /**
   * Checks whether the specified url is calling an auth-related endpoint.
   * @param url Url to check.
   */
  public isAuthUrl(url: string): boolean {
    return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
  }

  private toApi(...args: readonly string[]): string {
    const path = args.join('/');
    return new URL(path, this.appConfigService.apiUrl).toString();
  }
}
