import { DOCUMENT, formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDatasetInfo } from '@scp/common/core/models/chart-dataset-info';
import { GENERAL_DATE_FORMAT } from '@scp/common/core/utils/date-formats';
import { BaseChartDirective } from '@scp/common/shared/directives/base-chart.directive';
import { ActiveElement, ChartData, ChartOptions } from 'chart.js';
import us from 'us-atlas/states-10m.json';
import * as ChartGeo from 'chartjs-chart-geo';
import Color from 'color';

import { Topology } from 'topojson-specification';
import { GeoChartParams } from '@scp/common/core/models/geo-chart-params';
import { GeoChartService } from '@scp/common/core/services/geo-chart.service';
import { USA_STATES } from '@scp/common/core/models/usa-states';
import { NationalDispenseSortField } from '@scp/common/core/models/national-dispense-filter-params';

const TABLET_WIDTH_PX = 1440;

const FILLS_CHART_COLOR = '#00459f';
const ABANDONMENTS_CHART_COLOR = '#5f008b';

/** National dispense chart. */
@Component({
  selector: 'scpc-national-dispense-chart',
  templateUrl: './national-dispense-chart.component.html',
  styleUrls: ['./national-dispense-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NationalDispenseChartComponent implements OnInit, OnChanges {
  /** Chart info. */
  @Input()
  public chartInfo: ChartDatasetInfo[] = [];

  /** Params for chart render. */
  @Input()
  public chartParams: GeoChartParams | null = null;

  /** Current chart view (fills or abandonments count). */
  @Input()
  public nationalDispenseChartView = NationalDispenseSortField.FillsCount;

  /** Is chart placed on widget or not. */
  @Input()
  public isOnWidget = false;

  /** Base chart directive. */
  @ViewChild(BaseChartDirective)
  public chartRef: BaseChartDirective | null = null;

  /** Emits when state is selected. */
  @Output()
  public readonly stateSelected = new EventEmitter<string>();

  /** Chart data. */
  protected data!: ChartData;

  /** Current chart dataset values (fills or abandonments). */
  protected chartDatasetValues: number[] = [];

  /** Chart options. */
  protected options!: ChartOptions;

  private readonly nation: ChartGeo.Feature;

  private readonly states: ChartGeo.Feature;

  public constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DOCUMENT) private document: Document,
    private readonly geoChartService: GeoChartService,
  ) {
    const usAtlas = us as unknown as Topology;
    this.nation = this.geoChartService.initNation(usAtlas);
    this.states = this.geoChartService.initStates(usAtlas);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.setChartViewDetails();

    const window = this.document.defaultView?.window;
    this.options = this.getChartOptions(window?.innerWidth);
    this.data = this.getChartData();
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.chartRef?.chart != null) {
      if ('chartInfo' in changes || 'nationalDispenseChartView' in changes) {
        this.setChartViewDetails();
        this.data = this.getChartData();
        this.chartRef.chart.update();
      }
    }
  }

  private getTooltipTitle(index: number): string {
    const currentDate = formatDate(new Date(), GENERAL_DATE_FORMAT, this.locale);

    return `${this.chartInfo[0].labels[index]}: ${currentDate}` ;
  }

  private getTooltipLabel(index: number): string {
    const fills = `# of Fills: ${this.chartInfo[0].values[index]}`;
    const abandonments = `# of Abandonments: ${this.chartInfo[1].values[index]}`;

    return this.nationalDispenseChartView === NationalDispenseSortField.AbandonmentsCount ?
      abandonments :
      fills;
  }

  private getChartData(): ChartData {
    return {
      datasets: [
        {
          ...this.chartParams,
          outline: this.nation,
          showOutline: true,
          outlineBorderWidth: 2,
          data: this.chartDatasetValues.map((value, index) => ({
            value,
            feature: this.getFeature(this.states, index),
          })),
          label: 'State',
        },
      ],
      labels: this.chartInfo[0].labels,
    };
  }

  private getFeature(states: ChartGeo.Feature[], index: number): ChartGeo.Feature {
    return states.find(state => state.properties?.name === String(this.chartInfo[0].labels[index]));
  }

  private getChartOptions(windowWidth?: number): ChartOptions {
    const isTabletWidth = windowWidth && windowWidth <= TABLET_WIDTH_PX;
    if (isTabletWidth && this.chartParams != null) {
      this.chartParams = {
        ...this.chartParams,
        legendSize: 180,
      };
    }

    return {
      onClick: (_, elements) => this.onStateClick(elements),
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        color: {
          interpolate: value => this.getInterpolateColor(value),
          quantize: 7,
          missing: 'white',
          legend: {
            length: this.chartParams?.legendSize,
            position: this.chartParams?.legendPosition,
            align: this.chartParams?.legendAlign,
            width: 200,
          },
        },
      },
      animation: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          backgroundColor: '#41337d',
          displayColors: false,
          callbacks: {
            title: items => this.getTooltipTitle(items[0].dataIndex),
            label: item => this.getTooltipLabel(item.dataIndex),
          },
        },
      },
    };
  }

  private setChartViewDetails(): void {
    const fillsValues = this.chartInfo[0].values;
    const abandonmentsValues = this.chartInfo[1].values;

    const isAbandonmentsView = this.nationalDispenseChartView === NationalDispenseSortField.AbandonmentsCount;
    this.chartDatasetValues = isAbandonmentsView ? abandonmentsValues : fillsValues;
    this.chartParams = {
      ...this.chartParams,
      color: isAbandonmentsView ? ABANDONMENTS_CHART_COLOR : FILLS_CHART_COLOR,
    };
  }

  /**
   * Gets interpolated color.
   * @param value Scale value.
   */
  private getInterpolateColor(value: number): string {
    const colorChange = 1 - value;
    return Color(this.chartParams?.color).saturate(colorChange)
      .whiten(colorChange)
      .lighten(colorChange)
      .hex();
  }

  private onStateClick(chartElements: ActiveElement[]): void {
    if (chartElements.length > 0) {
      const feature = chartElements[0].element as ChartGeo.GeoFeature;
      const selectedState = USA_STATES.find(state => state.name === feature.feature.properties.name);
      this.stateSelected.emit(selectedState?.abbreviation);
    }
  }
}
