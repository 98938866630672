import { Pipe, PipeTransform } from '@angular/core';
import { Physician, PhysicianDetails } from '@scp/common/core/models/physician';

import { PhysicianFullNamePipe } from './physician-full-name.pipe';

/** Full name physician with suffix pipe. */
@Pipe({ name: 'physicianFullNameWithSuffix' })
export class PhysicianFullNameWithSuffixPipe implements PipeTransform {

  public constructor(private readonly physicianFullNamePipe: PhysicianFullNamePipe) { }

  /**
   * Returns the physician's full name with a prefix and academic degree.
   * @param physician Object of physician.
   */
  public transform(physician: PhysicianDetails | Physician): string {
    const suffix = physician.nameSuffix ? `, ${physician.nameSuffix}` : '';
    return `${this.physicianFullNamePipe.transform(physician)}${suffix}`;
  }

}
