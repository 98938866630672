import { Injectable } from '@angular/core';

import { Percents } from '../../models/percents';
import { Physician, TotalPhysicians } from '../../models/physician';

import { PhysicianDto, TotalPhysiciansDto } from './dto/physicians.dto';
import { IMapperFromDto } from './mappers';

/** Physician mapper. */
@Injectable({ providedIn: 'root' })
export class PhysicianMapper implements IMapperFromDto<PhysicianDto, Physician> {

  /** @inheritdoc */
  public fromDto(physicianDto: PhysicianDto): Physician {
    return {
      id: physicianDto.prescriber_id,
      firstName: physicianDto.first_name,
      middleName: physicianDto.middle_name,
      lastName: physicianDto.last_name,
      namePrefix: physicianDto.doctor_info.name_prefix,
      nameSuffix: physicianDto.doctor_info.name_suffix ?? '',
      phoneNumber: physicianDto.doctor_info.phone,
      address: {
        state: physicianDto.state,
        postalCode: physicianDto.postal_code,
        line1: physicianDto.doctor_info.first_line_address,
        line2: physicianDto.doctor_info.second_line_address ?? '',
        city: physicianDto.doctor_info.city,
      },
      totalPrescriptions: physicianDto.fills_count,
    };
  }

  /** @inheritdoc */
  public totalStatsFromDto(totalPhysiciansDto: TotalPhysiciansDto): TotalPhysicians {
    return {
      totalPrescriptionCount: totalPhysiciansDto.total_prescriptions_count,
      totalPrescriptionChange: new Percents(Number(totalPhysiciansDto.total_prescriptions_change)),
      medianPrescriptionCount: totalPhysiciansDto.median_prescriptions_count,
      medianPrescriptionChange: new Percents(Number(totalPhysiciansDto.median_prescriptions_change)),
      totalPhysiciansCount: totalPhysiciansDto.total_physicians_count,
      totalPhysiciansChange: new Percents(Number(totalPhysiciansDto.total_physicians_change)),
      topPrescriptionsCount: totalPhysiciansDto.top_prescriptions_count,
      topPrescriptionsChange: new Percents(Number(totalPhysiciansDto.top_prescriptions_change)),
    };
  }
}
