import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Pagination } from '../models/pagination';
import { Product } from '../models/product';

import { ProductsApiService } from './products-api.service';

/** Product service. */
@Injectable({ providedIn: 'root' })
export class ProductService {

  public constructor(
    private readonly productsApiService: ProductsApiService,
  ) { }

  /** Gets product list. */
  public getProducts(): Observable<Pagination<Product>> {
    return this.productsApiService.getProducts();
  }
}
