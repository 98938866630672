import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDatasetInfo } from '@scp/common/core/models/chart-dataset-info';
import { ChartDatasetParams } from '@scp/common/core/models/chart-dataset-params';
import { PointChartData } from '@scp/common/core/models/point-chart';
import { CHART_LABEL_DATE_FORMAT, GENERAL_DATE_FORMAT } from '@scp/common/core/utils/date-formats';
import { BaseChartDirective, MAX_LEGEND_HEIGHT } from '@scp/common/shared/directives/base-chart.directive';
import { ChartComponent } from '@scp/common/shared/directives/hovered-chart-table-directives/hovered-chart.directive';
import { ChartData, ChartOptions } from 'chart.js';

/** Prior authorization chart component. */
@Component({
  selector: 'scpc-prior-auth-line-chart',
  templateUrl: './prior-auth-line-chart.component.html',
  styleUrls: ['./prior-auth-line-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorAuthLineChartComponent implements OnInit, ChartComponent, OnChanges {
  /** Chart info. */
  @Input()
  public chartInfo!: ChartDatasetInfo[];

  /** Dataset params for chart render. */
  @Input()
  public chartDatasetParams: ChartDatasetParams<'line'>[] = [];

  /** Index of hovered item.  */
  @Input()
  public hoveredItem: PointChartData | null = null;

  /** Base chart directive. */
  @ViewChild(BaseChartDirective)
  public chartRef: BaseChartDirective | null = null;

  /** Chart data. */
  protected data!: ChartData;

  /** Chart options. */
  public options!: ChartOptions;

  public constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.data = this.getChartData();
    this.options = this.getChartOptions();
  }

  /** @inheritdoc */
  public ngOnChanges(changes: SimpleChanges): void {
    if (this.chartRef?.chart != null && 'chartInfo' in changes) {
      this.chartRef.chart.data = this.getChartData();
      this.chartRef.chart.update();
    }
  }

  private getLabels(): string[][] {
    return this.chartInfo[0].labels.map(dateLabel => formatDate(dateLabel, CHART_LABEL_DATE_FORMAT, this.locale).split(' '));
  }

  private getTooltipTitle(index: number): string {
    const dateLabel = this.chartInfo[0].labels[index];
    return formatDate(dateLabel, GENERAL_DATE_FORMAT, this.locale);
  }

  /** @inheritdoc */
  public getChartData(): ChartData {
    return {
      datasets: [
        {
          ...this.chartDatasetParams[0],
          data: this.chartInfo[0].values,
          label: 'With P.A.',
        },
        {
          ...this.chartDatasetParams[1],
          data: this.chartInfo[1].values,
          label: 'Without P.A.',
        },
      ],
      labels: this.getLabels(),
    };
  }

  private getChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            borderDash: [4, 4],
          },
        },
        y: {
          grid: {
            borderDash: [4, 4],
          },
        },
      },
      plugins: {
        legend: {
          onClick: event => event.native?.preventDefault(),
          labels: {
            boxHeight: MAX_LEGEND_HEIGHT,
          },
        },
        tooltip: {
          backgroundColor: '#41337d',
          displayColors: false,
          callbacks: {
            title: items => this.getTooltipTitle(items[0].dataIndex),
          },
        },
      },
    };
  }
}
