import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { RouterModule } from '@angular/router';

import { Chart, Legend, registerables, Tooltip } from 'chart.js';

import * as ChartGeo from 'chartjs-chart-geo';

import { LabelComponent } from './components/label/label.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { LoadingDirective } from './directives/loading.directive';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { RangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { AllowedRolesDirective } from './directives/allowed-roles';
import { PercentChangeComponent } from './components/percent-change/percent-change.component';
import { ExpansionPanelComponent } from './components/panel/expansion-panel.component';
import { DateWithPlaceholderPipe } from './pipes/date-with-placeholder.pipe';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';
import { BackButtonDirective } from './directives/back-button.directive';
import { DailyDispenseTrendChartComponent } from './components/charts/daily-dispense-trend-chart/daily-dispense-trend-chart.component';
import { PhysicianFullNamePipe } from './pipes/physician-full-name.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { ToggleComponent } from './components/toggle/toggle.component';
import { UserAvatarWithPlaceholderPipe } from './pipes/user-avatar-with-placeholder.pipe';
import { BaseChartDirective } from './directives/base-chart.directive';
import { RefillsChartComponent } from './components/charts/refills-chart/refills-chart.component';
import { SkeletonDirective } from './directives/skeleton.directive';
import { RejectChartComponent } from './components/charts/reject-chart/reject-chart.component';
import { PriorAuthDoughnutChartComponent } from './components/charts/prior-auth-doughnut-chart/prior-auth-doughnut-chart.component';
import { PriorAuthLineChartComponent } from './components/charts/prior-auth-line-chart/prior-auth-line-chart.component';
import { NationalDispenseChartComponent } from './components/charts/national-dispense-chart/national-dispense-chart.component';
import { HoveredChartDirective } from './directives/hovered-chart-table-directives/hovered-chart.directive';
import { HoveredTableDirective } from './directives/hovered-chart-table-directives/hovered-table.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { OutstandingRefillsChartComponent } from './components/charts/outstanding-refills-chart/outstanding-refills-chart.component';
import { PhysicianFullNameWithSuffixPipe } from './pipes/physician-full-name-with-suffix.pipe';

const GEO_CHART_REGISTERABLES = [
  ChartGeo.ChoroplethController,
  ChartGeo.ProjectionScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature,
];

Chart.register(
  ...registerables,
  ...GEO_CHART_REGISTERABLES,
  Tooltip,
  Legend,
);

const PRIVATE_DECLARATIONS: Type<unknown>[] = [ValidationMessageComponent];

const EXPORTED_DECLARATIONS: Type<unknown>[] = [
  LabelComponent,
  RangeDatepickerComponent,
  DatepickerComponent,
  LoadingDirective,
  AllowedRolesDirective,
  BackButtonDirective,
  UserFullNamePipe,
  PhysicianFullNamePipe,
  PhysicianFullNameWithSuffixPipe,
  AddressPipe,
  DateWithPlaceholderPipe,
  PercentChangeComponent,
  ExpansionPanelComponent,
  DailyDispenseTrendChartComponent,
  ToggleComponent,
  UserAvatarWithPlaceholderPipe,
  ToggleComponent,
  BaseChartDirective,
  RefillsChartComponent,
  OutstandingRefillsChartComponent,
  PriorAuthDoughnutChartComponent,
  RejectChartComponent,
  SkeletonDirective,
  PriorAuthLineChartComponent,
  NationalDispenseChartComponent,
  HoveredChartDirective,
  HoveredTableDirective,
  ClickOutsideDirective,
];

const EXPORTED_MAT_MODULES: Type<unknown>[] = [
  MatTableModule,
  MatIconModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule,
  MatRadioModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatChipsModule,
  MatProgressBarModule,
];

const EXPORTED_MODULES: Type<unknown>[] = [
  FormsModule,
  ReactiveFormsModule,
];

/** Common module. Contains entities shared across applications. */
@NgModule({
  declarations: [...PRIVATE_DECLARATIONS, ...EXPORTED_DECLARATIONS],
  imports: [
    CommonModule,
    RouterModule,
    ...EXPORTED_MODULES,
    ...EXPORTED_MAT_MODULES,
  ],
  exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES, ...EXPORTED_MAT_MODULES],
  providers: [DatePipe, PhysicianFullNamePipe],
})
export class CommonSharedModule { }
